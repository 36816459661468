import { onMounted } from 'vue';

export default function ({props, locState, computed,methods}, {emitChanges}){

    const {searchAsync, modelValue, selectedOptionOnInit, outValueKey, options} = props

    onMounted(() => {


        if(selectedOptionOnInit || selectedOptionOnInit == 0){
                if( typeof props.selectedOptionOnInit == "object" && options?.length > 0 ){
                    const {key, value} = props.selectedOptionOnInit

                    if(key && value){
                        const find = props.options.find(item=>item[key] == value)
                        if(find){
                            methods.selectOptionHandle(find)
                        }
                    }
                }else{
                    if(!modelValue){
                        methods.selectOptionHandle(props.options[props.selectedOptionOnInit])
                    }

                }
        }

        // if(outValueKey){
        //     if(typeof modelValue == 'number'){
        //         const option = props.options.find(item=>item[outValueKey] == modelValue)
        //         if(option){
        //             methods.selectOptionHandle(option)
        //         }
        //     }
        // }


        if (searchAsync == '') {
            console.warn(`searchAsync: input url form component with name - ${props.name}`);
        }

    });




}
