<template>

    <component :is="href ? 'a': 'button'" class="acms-v-btn btn d-flex align-items-center"
            :class="getClass"
            :style="getStyle"
            :href="href"
    >

        <template v-if="icon">

            <acms-v-icon  :size="iconSize" :name="icon"
                          :updatesCount="updatesCount"
                          :color="iconColor"
                          class="acms-v-btn-icon "/>
        </template>


            <template v-if="title">
                <div class="acms-v-btn-title" :class="getContentClass">
                {{ title }}
                </div>
            </template>
            <template v-else-if="$slots?.default">
                <div class="acms-v-btn-content d-flex" :class="getContentClass">
                    <slot name="default"></slot>
                </div>
            </template>


        <div class="acms-v-btn-updates" v-if="updatesCount ">{{updatesCount }}</div>

    </component>


</template>

<script>
export default {
    name: 'AcmsVBtn',
};
</script>

<script setup="">

import { computed } from 'vue';
import AcmsVIcon from '@ui/Icon.vue'

const props = defineProps({

    title: String,
    preset: String,
    width:String,

    icon:String,
    iconColor:String,
    iconSize:String,
    href:String,

    updatesCount:String


});

const getStyle  = computed(()=>{
    return {width: (props.width ?? null ) + 'px'}
})

const getClass = computed(() => {

    const preset = props.preset;
    return {
        'acms-v-btn--simple text-primary': preset == 'simple-primary',
    };
});

const getContentClass = computed(()=>{
    return (props.iconLeft || props.icon) ? 'ms-1' : ''
})




</script>

<style lang="scss">


.acms-v-btn {
    padding: 7px 12px; // 10px 16px;
    justify-content: center;
    font-size: 16px;
    line-height: 22px;
    position: relative;
    &.btn-sm{
        padding: 4px 8px;
        font-size: 14px;
        line-height: 20px;
    }
    &.btn-row-action{
        height: 38px;
    }
    &.btn-border-black{
        border: 1px solid #4B5563;
        color: #4B5563;
    }

}

.acms-v-btn-icon {
    & + .acms-v-btn-title {
        margin-left: 4px;
    }
}

.acms-v-btn--simple {
    padding: 0 !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}


button.btn-border{
    border: 1px solid #E9ECEF;
}


.acms-v-btn-updates{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #0891B2;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}



</style>
