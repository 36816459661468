<template>
    <Head :title="'IP Security '+ form.ip_address + ' - VIP Fares'"></Head>

    <AppMainLayout
        :title="form.ip_address"
        :breadcrumbs="[{title:'IP Security', link: getRoute('ip.security.index')},  form.ip_address ?? 'ip']"
    >
        <template #content>
            <div class="bg-white block-content container-sm">
                <div class="row">
                    <acms-v-form-field
                        label="IP Address"
                        name="ip_address"
                        placeholder="ex. 192.168.161.93"
                        modeInput
                        v-model="form.ip_address"
                        :errors="form.errors?.ip_address"
                        class=" col-4"
                        :readonly="getDisabled"
                        required
                    />
                    <acms-v-form-field
                        label="Status"
                        name="status"
                        :mode-select="{
                            options:[
                              {title:'Forbidden', id: 0, },
                              {title:'Allowed', id: 1, },
                            ],
                            outValueKey:'id',
                        }"

                        v-model="form.status"
                        :errors="form.errors?.status"
                        class="col-4"
                        :readonly="getDisabled"
                    />
                    <acms-v-form-field
                        label="Tag"
                        name="tag_id"
                        :modeSelect="{
                            options:tags,
                            outValueKey:'id'
                        }"
                        v-model="form.tag_id"
                        :errors="form.errors?.tag_id"
                        class=" col-4"
                        :readonly="getDisabled"
                    />
                    <acms-v-form-field
                        label="Description"
                        name="description"
                        :modeTextarea="{minHeight:96,resize:true}"
                        placeholder="Placeholder"
                        v-model="form.description"
                        :errors="form.errors?.description"
                        class=" col-12"
                        :readonly="getDisabled"
                    />
                </div>
            </div>
            <acms-v-bottom-actions
                :hide-save-buttons="getDisabled"
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('ip.security.index'))"
            />
        </template>
    </AppMainLayout>
</template>
<script>

export default {
    name: 'IpSecurityEditPage',
};
</script>

<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import {computed} from "vue";

const props = defineProps({
    tags:Array,
    resource: Object,
    message: String,
    status: String,
    actions: {
        type: Array,
        default: () => []
    },
});
const getDisabled = computed(()=>{
    return !props.actions.includes('Update')
})
const AlertsManager = useAlertsManagerStore()

const {ip_address,status,tag_id,description,resource_id = props.resource.id } = props.resource

const form = useForm({
    ip_address,status,tag_id,description,resource_id,
})

const sendFormHandle = (callback = ()=>{}) =>{
    form.put(getRoute('ip.security.update', props.resource.id),{
        onSuccess(){
            if(props.status == 'error'){
                AlertsManager.add('Server Error', 'error')
            }else{
                AlertsManager.add('Ip Security successfully updated')
            }
            callback?.()
        },
        onError(){
            AlertsManager.add('Ip Security has errors', 'error')
        }
    })
}

const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(()=>router.visit(getRoute('ip.security.index')))
}
</script>

<style scoped>

</style>
