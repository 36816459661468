<template>
    <label class="acms-v-label form-label ">
        <span class="fw-bold me-1">{{ getLabel }}</span>
        <span v-if="required" class="text-danger">*</span>
        <span v-if="labelAfter" class="text-gray-dark">{{labelAfter}}</span>
    </label>
</template>

<script>
export default {
    name: 'AcmsVLabel',
};
</script>

<script setup>

import { computed } from 'vue';

const props = defineProps({
    label: String,
    title: String,
    labelAfter: String,
    required: Boolean,

});

const getLabel = computed(() => {
    const { label, title } = props;
    const labelToDisplay = label ? label : title ? title : ''
    return  labelToDisplay.charAt(0).toUpperCase() + labelToDisplay.slice(1);
});

</script>


<style lang="scss">
.acms-v-label {
    font-size: 14px;
}


</style>
