<template>
    <!--    <div class="acms-v-form-field-element"></div>-->
    <div class="acms-v-input d-flex align-items-centerc"

    >
        <div class="acms-v-input-before" v-if="$slots?.before">
            <slot name="before"></slot>
        </div>


        <input class="form-control"
               :class="elementClass"
               :required="required"
               :disabled="disabled"
               :placeholder="placeholder"
               :name="name"
               :type="type"
               :value="modelValue"
               :style="style"
               @input="inputHandle"
               @wheel.stop.prevent
               :readonly="readonly"
        >

    </div>

</template>

<script>
export default {
    name: 'AcmsVInput',
};
</script>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';


import fieldProps, { defaultAttributesProps, iconProps, modesProps } from '../field.props';

const emit = defineEmits(['update:modelValue', 'onInput']);

const props = defineProps(
    {
        ...defaultAttributesProps,
        modelValue: [Number, String],
        elementClass:String,
    },
);

// watch(()=>props.modelValue, (val)=>{
//     console.log(' val ', val);
// })




const inputHandle = (e) => {

    emit('update:modelValue', e.target.value)
    emit('onInput', e.target.value)
}


onMounted(() => {
    // console.log(' props. ', props);
});

</script>


<style lang="scss">

.input-group-text {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.acms-v-input{
    .form-control:disabled{
        background-color: #fff;
    }
}



table{
    .acms-v-input{
        input{
            border: 1px solid #CED4DA;
            border-radius: 4px;
            font-size: 12px;
            line-height: 16px;
            padding: 4px 12px;

        }
    }

    .acms-v-input-before{
        padding: 4px 8px;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #CED4DA;
        border-radius: 4px 0 0 4px;
        border-right: 0;
        &+input{
            border-radius: 0 4px 4px 0;
        }
    }
}

</style>
