import { computed } from 'vue';

export const selectUtils = {
    retrieveSelectedProps (parentProps, propsToRetrieve) {


            let object = {};
            Object.keys(propsToRetrieve).forEach(key => {
                if(key in parentProps){
                    object[key] = parentProps[key];
                }
            });

            return object;

    },
};
