import { defineAsyncComponent } from 'vue';

// import PassportIndex from './components/app/pages/Index.vue'
// import PassportCreate from './components/app/pages/Create.vue'




import AcmsVFormField from '@ui/FormField/FormField.vue';
import AcmsVIcon from '@ui/Icon.vue';
import AcmsVLabel from '@ui/FormField/partials/Label.vue';
import AcmsVSwitcher from '@ui/FormField/elements/Switcher.vue';
import AcmsVInput from '@ui/FormField/elements/Input.vue';
import AcmsVDatepicker from '@ui/FormField/elements/Datepicker.vue';
import AcmsVSelect from '@ui/FormField/elements/Select/Select.vue';
import AcmsVDropdown from '@ui/Dropdown.vue';
import AcmsVModal from '@ui/Modal/Modal.vue';
import AcmsVAlert from '@ui/Alert.vue';
import AcmsVTabs from '@ui/Tabs.vue';
import AcmsVTable from '@ui/Table/Table.vue';
import AcmsVBadge from '@ui/Badge.vue';
import AcmsVRatinger from '@ui/Ratinger.vue';
import AcmsVPopover from '@ui/Popover.vue';
import AcmsVBtn from '@ui/Btn.vue';
import AcmsVBtnGroup from '@ui/BtnGroup.vue';
import AcmsVModalAlert from '@ui/ModalAlert.vue';
import AcmsVCard from '@ui/Card.vue';
import AcmsVCollapse from '@ui/Collapse.vue';
import AcmsVMultiRowCrud from '@ui/MultiRowCrud.vue';
import AcmsVBottomActions from '@ui/BottomActions.vue';
import AppMainLayout from "@layouts/AppMainLayout.vue";



const uiComponentsForGlobalUse = {
    'acms-v-form-field': AcmsVFormField,
    'acms-v-label': AcmsVLabel,
    'acms-v-icon': AcmsVIcon,
    'acms-v-switcher': AcmsVSwitcher,
    'acms-v-input': AcmsVInput,
    'acms-v-datepicker': AcmsVDatepicker,
    'acms-v-select': AcmsVSelect,
    'acms-v-dropdown': AcmsVDropdown,
    'acms-v-modal': AcmsVModal,
    'acms-v-alert': AcmsVAlert,
    'acms-v-tabs': AcmsVTabs,
    'acms-v-table': AcmsVTable,
    'acms-v-badge': AcmsVBadge,
    'acms-v-ratinger': AcmsVRatinger,

    'acms-v-popover': AcmsVPopover,
    'acms-v-btn': AcmsVBtn,
    'acms-v-btn-group': AcmsVBtnGroup,
    'acms-v-card': AcmsVCard,
    'acms-v-collapse': AcmsVCollapse,
    'acms-v-multi-row-crud': AcmsVMultiRowCrud,
    'acms-v-bottom-actions': AcmsVBottomActions,
    'acms-v-modal-alert': AcmsVModalAlert,


    // 'app-main-layout': AppMainLayout
};

export default function (app, types = []) {

    //layouts

    if(types.includes('layout')){
        app.component('AppMainLayout', AppMainLayout);
    }

//ui components
    if(types.includes('ui')){
        Object.entries(uiComponentsForGlobalUse).forEach(([key, component]) => {
            app.component(key, component);
        });
    }


//pages
    if(types.includes('pages')){
        // app.component('PassportIndex', PassportIndex);
        // app.component('PassportCreate', PassportCreate);

        // Object.entries(ClientsPages).forEach(([key, component]) => {
        //     app.component(key, component);
        // });

    }

    return app;
}
