<template>

    <div class="acms-v-collapse">


        <div class="acms-v-collapse-list">

            <div class="acms-v-collapse-item" v-for="(item, index) in items">
                <div class="acms-v-collapse-toggle d-flex justify-content-between"
                     :class="{active: isActive(item, index)}"
                     @click="openHandle(item,index)"
                >

                    <div class="acms-v-collapse-toggle-content align-items-center d-flex w-100">
                        <template v-if="!$slots['toggle']">
                            {{item?.title}}
                        </template>
                        <slot name="toggle" :item="item" :index="index +1"></slot>
                    </div>

                    <acms-v-btn icon="angle-down" class="acms-v-collapse-toggle-status p-2 bg-gray-light"
                                @click="openHandle(item,index)"/>

                </div>

                <Transition name="fade">
                    <div class="acms-v-collapse-content" v-if="isActive(item, index)">
                        <keep-alive>
                            <slot name="content" :item="item"></slot>
                        </keep-alive>
                    </div>
                </Transition>


            </div>
        </div>


    </div>


</template>

<script>
export default {
    name: 'AcmsVCollapse',
};
</script>
<script setup="">
import { reactive } from 'vue';

const props = defineProps({
    items: Array,

    activeIndex: [Number, String],

});

const locState = reactive({
    active: props.activeIndex ?? -1,
});

const isActive = (item, index) => {

    if (locState.active > -1) {
        return (locState.active == item?.id || locState.active == index);
    }
    return false;

};

const openHandle = (item, index) => {

    if (locState.active == item?.id || locState.active == index) {
        locState.active = -1;

    } else {
        locState.active = item?.id ?? index;

    }
};


</script>

<style lang="scss">


.acms-v-collapse-toggle {
    background: #FFFFFF;
    border-bottom: 1px solid #E9ECEF;
    height: 56px;
    padding: 12px;

    &.active {
        background-color: #F6F7F9;

        .acms-v-collapse-toggle-status .acms-v-icon {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.acms-v-collapse-content {
    padding: 12px;
}

</style>
