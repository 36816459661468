import { createPopper } from '@popperjs/core';
import $ from 'jquery';

const tooltipMethods = {
    getTemplate (text, params) {
        const html = params?.html
        console.log(' params ', params);

        return `<div class="acms-vd-tooltip show"   role="tooltip">

                <div class="acms-vd-tooltip-content" >
                  ${text ? text : html}
                </div>
        <div class="acms-vd-tooltip-arrow"
             data-popper-arrow></div>

        </div>`;
    },
    create (text, params) {

        const el = $(this.getTemplate(text, params));
        $('body').append(el);
        return el;
    },

};

const placements = ['top', 'left', 'right', 'bottom'];

const directiveTooltip = {
    mounted (el, binding, vnode) {
        let vm = vnode.context;
        let params = binding.value;
        let modifiers = binding.modifiers;
        const paramsIsObject = typeof params != 'string';

        let text = params;

        let elementParams = {
            placement : 'top'
        }

        if (paramsIsObject) {
            text = params.text;
            elementParams = {
                ...elementParams,
                ...params
            } ;
        }

        el.mouseleaveEventHandler = function (event) {
            if(el?.tooltipElement){
                el.tooltipElement.classList.remove('show');
            }
        };

        el.mouseoverEventHandler = function (event) {

            if (el.contains(event.target)) {

                if (el?.tooltipElement) {

                    el.tooltipElement.classList.add('show');

                } else {

                    const tooltipEl = tooltipMethods.create(text, elementParams);
                    el.tooltipElement = tooltipEl[0];

                    createPopper(el, el.tooltipElement, {
                        placement : elementParams.placement,
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    });
                }



            }

        };

        // el.clickEventHandler = function (event) {
        //
        //     if (!(el.tooltipElement == event.target || el.contains(event.target))) {
        //         // tooltipElement
        //     }
        // };

        setTimeout(() => {
            document.body.addEventListener('mouseover', el.mouseoverEventHandler);
            el.addEventListener('mouseleave', el.mouseleaveEventHandler);
        }, 0);

    },
    beforeUnmount (el) {
        document.body.removeEventListener('mouseover', el.mouseoverEventHandler);
        el.removeEventListener('mouseleave', el.mouseleaveEventHandler);

        // document.body.removeEventListener('click', el.clickEventHandler);
    },
};
export default directiveTooltip;
