<template>

    <!--    -->
    <div class="acms-v-modal"
         :class=" cleanTemplate ? 'acms-v-modal--clean' : ''"
         v-if="show">
        <div class="modal show" tabindex="-1"
             aria-labelledby="modal-block-vcenter" aria-modal="true" role="dialog" style="display: block;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="acms-v-modal-body modal-content">
                    <div class="block block-rounded block-transparent mb-0  d-flex">

                        <div class="acms-v-modal-body-left" :class="leftClass" v-if="canShowLeftBlock($slots)">
                            <div class="acms-v-modal-header block-header block-header-default">

                                <div class="acms-v-modal-title ">{{ leftTitle }}</div>

                                <slot name="left-header"></slot>
                            </div>
                            <div class="block-content">
                                <slot name="left-content"></slot>
                            </div>

                            <div class="block-content block-content-full">
                                <slot name="left-footer"></slot>
                            </div>


                        </div>

                        <div class="acms-v-modal-body-middle w-100">

                            <div class="acms-v-modal-header block-header block-header-default"
                            >
                                <div class="acms-v-modal-title ">{{ title }}</div>
                                <div class="  d-flex align-items-center">

                                    <slot name="header-right"></slot>

                                    <button type="button" class="btn-block-option"
                                            @click="hideHandle"
                                            data-bs-dismiss="modal" aria-label="Close">
                                        <acms-v-icon name="close" color="text-dark-gray" size="24"/>
                                    </button>
                                </div>
                            </div>

                            <div class="acms-v-modal-content block-content ">

                                <slot name="content" :hideHandle="hideHandle"></slot>

                            </div>

                            <div  class="acms-v-modal-footer block-content block-content-full d-flex  " v-if="$slots?.footer || $slots?.['footer-buttons']">

                                <template v-if="$slots?.footer">
                                    <slot name="footer" :hideHandle="hideHandle"></slot>


                                </template>

                                <div class="acms-v-modal-footer-buttons" v-if="$slots?.['footer-buttons']">

                                    <slot name="footer-buttons" :hideHandle="hideHandle">

                                    </slot>
                                </div>


                                <!--                            <button type="button" class="btn btn-sm btn-alt-secondary me-1" data-bs-dismiss="modal">Cancel</button>-->
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <div class="modal-backdrop show" @click="hideHandle"></div>
        </div>

    </div>


</template>

<script>
export default {
    name: 'AcmsVModal',
};
</script>


<script setup="">

import { computed, watch } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: 'Modal title',
    },

    leftTitle: String,
    leftClass: String,

    show: [Boolean],

    size: {
        type: String,
        default: 'medium',
    },

    cleanTemplate: Boolean,

});

watch(() => props.show, (val) => {
    if (val) {
        document.body.classList.add('overflow-hidden');
    } else {
        document.body.classList.remove('overflow-hidden');
    }
});

const emit = defineEmits(['hide']);

const showHandle = () => {

};

const canShowLeftBlock = (slots) => {

    return slots?.['left-header'] || slots?.['left-content'] || slots?.['left-footer'];

};

const hideHandle = () => {

    emit('hide');
};

const modalWidth = computed(() => {
    const { size, width } = props;

    if (size == 'super-large') {
        return '1650px';
    }

    if (size == 'large') {
        return '1200px';
    }
    if (['medium', 'middle', 'med'].includes(size)) {
        return '800px';
    }
    if (['med-med'].includes(size)) {
        return '600px';
    }
    if (['med-sm'].includes(size)) {
        return '500px';
    }
    if (size == 'sm-lg') {
        return '400px';
    }
    if (size == 'small') {
        return '300px';
    }
});


</script>

<style lang="scss">

$grey: #F8F9FA;

.acms-v-modal {

    .modal {
        width: unset;
        height: unset;
        inset: 0;
        z-index: 10000 ;
    }

    .modal-backdrop {
        z-index: -1;
    }

    .modal-dialog {
        max-width: v-bind(modalWidth);
    }

    .acms-v-modal-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #4B5563;

    }

    .acms-v-modal-header {
        background-color: $grey;
        min-height: 56px;
    }
    .acms-v-modal-body{
        border-radius: 4px;
        overflow: hidden;
    }

    .acms-v-modal-footer {
        background-color: $grey;
    }

    .acms-v-modal-content {
        padding: 20px;
    }

    .acms-v-modal-footer{
        padding: 20px 20px;
    }

    .acms-v-modal-footer-buttons{
        display: flex;
        gap: 12px;
        margin-left: auto;
    }

}
.acms-v-modal-body-left{
    border-right: 1px solid #E9ECEF;
}

.acms-v-modal--clean {
    .acms-v-modal-header {
        display: none;
    }

    .acms-v-modal-body {
        background: transparent;

    }

    .acms-v-modal-content {
        padding: 0;
        background: transparent;
    }
}


</style>
