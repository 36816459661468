import {ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";


export default function (route, sendData = {}, sendParams = {}) {

    const {
        limit = 20,
        search = null,
        column = null,
        direction = null,
        page = 1
    } = sendData

    const {
        sendType = 'get',
        only = null,
        preserveScroll=false,
        noRequest= false,
        additionalData = null
    } = sendParams


    const tableForm = ref({
        limit,
        search,
        column,
        direction,
        page
    })


    watch(tableForm, (val) => {

        const queryObject = {
            limit: tableForm.value.limit
        };
        ['search', 'column', 'direction', 'page'].map(key => {
            if (tableForm.value[key]) {
                queryObject[key] = tableForm.value[key]
            }
        })

        if(additionalData){
            Object.entries(additionalData).forEach(([key,value])=>{
                queryObject[key] = value
            })
        }

        const getCorrectSendType = ['get', 'post'].includes(sendType) ? sendType : 'get'

        const form = useForm(queryObject)

        const formParams = {preserveState: true, preserveScroll}


        if (only) {
            formParams.only = only
        }

        if(!noRequest){
            form[getCorrectSendType](route, formParams)
        }

    }, {deep: true})

    const tableSortHandle = (tableSort) => {
        tableForm.value.column = tableSort.column
        tableForm.value.direction = tableSort.direction
    }


    return {tableForm, tableSortHandle}

}
