<template>
    <div class="acms-v-textarea" :class="{'acms-v-textarea--resize': resize }">

        <textarea

            class="form-control"
            :required="required"
            :name="name"
            :value="modelValue"
            :placeholder="placeholder"
            @input="$emit('update:modelValue', $event.target.value)"
            ref="textareaRef"
            :disabled="disabled"
            :style="getStyle"
            :readonly="readonly"
        ></textarea>
    </div>


</template>

<script>


export default {
    name: 'vTextarea',

};
</script>
<script setup>

import {isNumber} from 'lodash';


import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';

import fieldProps, {defaultAttributesProps} from '@components/ui/FormField/field.props';

const props = defineProps(
    {
        ...defaultAttributesProps,
        minHeight: [String, Number],
        resize: [Boolean, String],
    });


const locState = reactive({
    initialHeight: props.minHeight ?? null,
    height: '',
});

const getStyle = computed(() => {

    const object = {
        minHeight: locState.initialHeight + 'px !important'
    }
    if (props.resize == 'auto') {
        object.height = isNumber(locState.height) ? locState.height + 'px' : locState.height
    }
    return object
});

const textareaRef = ref();

const changeHeightHandle = (val, preVal = '') => {
    if (props.resize == 'auto') {
        setTimeout(() => {

            if (val == '') {
                locState.height = locState.initialHeight;
            } else {
                const rowsCount = (textareaRef.value?.value.match(/\n/g) || []).length;
                // console.log(' rowsCount ', rowsCount);

                const currentHeight = textareaRef.value.scrollHeight;
                if (val?.length != preVal?.length) {
                    locState.height = currentHeight;
                }

            }
        }, 0);
    }

};

watch(() => props.modelValue, (val, preVal) => {
    changeHeightHandle(val, preVal);
}, {immediate: true});

onBeforeMount(() => {
    // console.log(' props ', props);
    changeHeightHandle(props.modelValue,);
});

onMounted(() => {

})

</script>

<style lang="scss">

.acms-v-textarea--resize {
    textarea.form-control {
        resize: vertical;
    }
}

textarea.form-control {
    min-height: 56px !important;
    max-height: 70vh;
    padding: 8px 16px;
    //overflow: hidden;
    resize: none;

}
</style>
