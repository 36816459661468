<template>
    <div class="user-group-permission-selecter">



        <template v-if="groupTypeInput">
            <div class="d-flex">
                <acms-v-form-field
                    :label="labelGroup"
                    placeholder="ex. Group for Passport"
                    modeInput
                    class=" col-4"
                    v-model="locState.group"
                    :errors="errors?.role_id || errors?.name"
                    :readonly="readonly"
                    :required="required"
                />

                <acms-v-form-field
                    class="ms-6"
                    label="Contact view counter"
                    :model-value="counterView"
                    type="number"
                    @update:modelValue="$emit('update:counterView', $event)"
                >
                </acms-v-form-field>
            </div>
        </template>


        <acms-v-tabs
            :items="getTabsItems"
            content-class="p-0 pt-4"
            navTheme="alt"
            class="permissions-tabs"
            navWrapClass="permissions-tabs-nav"
            @afterChangeTab="onChangeTabHandle"
        >
            <template v-for="item in permissionsGroups" #[getSlotName(item)]>

                <template v-if="!groupTypeInput">
                    <acms-v-form-field
                        :label="labelGroup"
                        placeholder="Select option"
                        :name="'group-'+getName(item)"
                        :modeSelect="{
                                options:getGroupOptions,
                                    optionKeyTitle:'name',
                                    outValueKey: 'id',
                                    toggleTagKeyTitle:'name',
                                    selectedOptionOnInit:0
                                }"
                        name="group"
                        class="col-4"
                        :modelValue="getRoleModelValue(item)"
                        @update:modelValue="($event)=>locState.group[getName(item)] = {role_id: $event, client_id: item.id}"

                        :errors="errors?.roles"
                        :readonly="readonly"
                    />

<!--                    v-model="locState.group[getName(item)].role_id"-->
                </template>
                <h3 class="mb-2 mt-2" v-if="showTitle">Additional permissions</h3>
                <acms-v-form-field
                    :mode-select="{
                                multi:true,
                                search:true,
                                searchPlaceholder:'search',
                                options: item.permissions,
                                optionKeyTitle:'name',
                                toggleTagKeyTitle: 'name'
                        }"
                    label="Permissions"
                    name="permissions"
                    placeholder="Add permissions separated by comma/space"
                    v-model="locState.permissions[item.name]"
                    :readonly="readonly"
                />
            </template>
        </acms-v-tabs>
    </div>
</template>

<script>
export default {
    name: "UserGroupPermissionsBox"
}
</script>


<script setup="">
import {computed, onBeforeMount, onMounted, reactive, watch} from "vue";
import AcmsVFormField from "@ui/FormField/FormField.vue";
import {usePage} from "@inertiajs/vue3";

const emit = defineEmits(['update:group', 'update:permissions', 'update:counterView', 'changeTab'])
const props = defineProps({
    showTitle: Boolean,
    labelGroup: String,
    group: [String, Object, Number],
    permissions: Object,
    groupTypeInput: Boolean,
    counterView: [String, Number],
    permissionsGroups: Array,
    errors: Object,
    groupsOptions: {
        type: Array,
        default: ['Test1', 'Test2', 'Test3']
    },
    selectedPermissions: Array,
    readonly: Boolean,
    required: Boolean
})

const $page = usePage()

const locState = reactive({
    group: null,
    permissions: {},
})

watch(() => locState.group, (val) => {
    emit('update:group', val)

}, {deep: true})
watch(() => locState.permissions, (val) => {
    emit('update:permissions', val)
}, {deep: true})


const getGroupOptions = computed(()=>{
    const defaultGroupOption = $page.props?.defaultOptions?.defaultGroup
    const options = [
        ...props.groupsOptions
    ]

    if(defaultGroupOption){
        options.unshift(defaultGroupOption)
    }

    return options
})

const getTabsItems = computed(() => {
    let object = {}

    props.permissionsGroups.forEach(item => {
        object[item.name.replaceAll(' ', '-').toLowerCase()] = item.name
    })

    return object
})


const onChangeTabHandle = (e) => {
    emit('changeTab', e)
}

const getName = (item) => {
    return item.name.replaceAll(' ', '-').toLowerCase()
}
const getSlotName = (item) => {
    return `tab-${getName(item)}`
}

onMounted(() => {
    emit('update:group', locState.group)
    emit('update:permissions', locState.permissions)
})

const getRoleModelValue = (item)=>{

    const name = getName(item)

    if(!locState.group?.[name]){
        locState.group[getName(item)] = {
            role_id: null,
            client_id: item.id
        }
    }
    return  locState.group[getName(item)].role_id

}

onBeforeMount(() => {
    if (props.groupTypeInput) {
        locState.group = props.group ?? null
    } else {
        locState.group = props.group ?? {}
    }

    if (props.selectedPermissions) {
        props.permissionsGroups.forEach(pgItem => {
            locState.permissions[pgItem.name] = props.selectedPermissions.reduce((acc, sItem) => {

                if (sItem.client_id == pgItem.id) {
                    acc.push(sItem)
                }
                return acc
            }, [])

        })
    } else {
        locState.permissions = {}
    }

})

</script>
<style lang="scss">
.permissions-tabs {
    .nav-tabs-alt {
        border: 0;
    }

    .permissions-tabs-nav {
        border-bottom: 1px solid #CED4DA;
    }
}
</style>
