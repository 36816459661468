<template>
    <Head :title="'Permission '+ resource?.name + '  - VIP Fares'"></Head>
    <AppMainLayout
        :title="resource?.name"
        :breadcrumbs="[{title:'Permissions', link: getRoute('user.permissions.index')},  resource.name ?? 'Name']"
    >

        <template #content>
            <div class="bg-white block-content container-sm">
                <div class="mb-4">
                    <acms-v-switcher
                        v-model="form.active"
                        title="Active"
                        class="ms-auto"
                        :disabled="getDisabled"
                    />
                </div>
                <div class="row">
                    <acms-v-form-field
                        label="Name"
                        name="name"
                        modeInput
                        :model-value="resource.name"
                        class=" col-6"
                        disabled
                    />
                    <acms-v-form-field
                        label="Project"
                        name="project"
                        modeInput
                        disabled
                        :model-value="resource.project"
                        class=" col-6"
                        :readonly="getDisabled"
                    />
                    <acms-v-form-field
                        label="Function"
                        name="function"
                        modeInput
                        v-model="form.function"
                        class=" col-12"
                        :readonly="getDisabled"
                    />
                    <acms-v-form-field
                        label="Description"
                        name="description"
                        modeTextarea
                        v-model="form.description"
                        class=" col-12"
                        :readonly="getDisabled"
                    />
                </div>
            </div>
            <acms-v-bottom-actions
                :hide-save-buttons="getDisabled"
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('user.permissions.index'))"
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserGroupEditPage',
};
</script>

<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import {computed} from "vue";

const props = defineProps({
    resource: Object,
    message: String,
    status: String,
    actions: {
        type: Array,
        default: () => []
    },
});

const AlertsManager = useAlertsManagerStore()

const {active, title, description,} = props.resource
const getDisabled = computed(()=>{
    return !props.actions.includes('Update')
})
const form = useForm({
    active,
    'function': props.resource.function,
    description,
})
const sendFormHandle = (callback = ()=>{}) =>{
    form.put(getRoute('user.permissions.update', props.resource.id),{
        onSuccess() {
            if(props.status == 'error'){
                AlertsManager.add('Server Error', 'error')
            }else{
                AlertsManager.add('Permission successfully updated')
            }
            callback?.()
        },
        onError() {
            AlertsManager.add('Permission has errors', 'error')
        }
    })
}
const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(()=>router.visit(getRoute('user.permissions.index')))
}

</script>

<style scoped>

</style>
