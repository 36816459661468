<template>

    <div class="acms-v-form-field "
         :name="name"
         :data-v-form-field="childComponent.name"
         :class="{'acms-v-form-field--disabled': disabled}"
    >

        <template v-if="label">

                <acms-v-label :label="label" :labelAfter="labelAfter" :required="required"/>

        </template>

        <template v-if="!$slots?.custom">

            <div class="acms-v-form-field-wrap d-flex"
            >

                <template v-if="childComponent.name.length > 0">
                    <component :is="childComponent.component"
                               v-bind="getChildProps"
                               class="acms-v-field-element"
                               :class="[getHint.elementClass]"
                               v-model="locState.value"
                               v-on="ELEMENT_LISTENERS(childComponent.name,$emit)"
                               data-v-form-element

                               :required="required"
                               :disabled="disabled"
                               :placeholder="placeholder"
                               :name="name"
                               :type="type"
                               :elementClass="elementClass"
                               :readonly="readonly"
                               :inert="inert"
                    >

                    </component>

                    <template v-if="hintWarning">
                        <acms-v-icon name="exclamation"  class="hint-warning-icon" color="text-warning"/>
                    </template>

                </template>
                <slot name="right"></slot>

                <template v-if="withIcon">
                      <span class="input-group-text">
                        <acms-v-icon :name="icon"></acms-v-icon>
                    </span>
                </template>

                <template v-if="hintIcon">
                    <acms-v-icon class="acms-v-form-field-right-icon" :name="hintIcon"
                                 :size="hintIconSize" :class="hintIconClass" />
                </template>

            </div>

            <template v-if="getHint.show && errors">
                <AcmsVHint
                           style="min-height: 21px"
                           v-bind="{errors, hint, hintValid, name}"
                           :class="getHint.hintClass"
                />
            </template>

            <template v-else>

                <div v-if="!hideHint" style="min-height: 21px"></div>

            </template>

        </template>

        <slot name="custom"></slot>
        <template v-if="debug">
            <br>
            <pre>{{ locState.value }}</pre>

        </template>
    </div>


</template>

<script>
export default {
    name: 'AcmsVFormField',
};
</script>


<script setup>
import { onMounted, reactive, watch } from 'vue';
import fieldProps from './field.props';
import FieldComputed from './field.computed';
import { ELEMENT_LISTENERS } from '@components/ui/FormField/constants';

import AcmsVHint from './partials/Hint.vue'

const emit = defineEmits(['update:modelValue', 'onInput', 'clickOnDropdownOption']); //todo  need add current events

const props = defineProps(
    {
        ...fieldProps,
        labelAfter: String,
    }
);

const locState = reactive({
    value: props.modelValue,
});

watch(() => locState.value, (val) => {
    // console.log(' val ', val);
    emit('update:modelValue', val);
});


watch(() => props.modelValue, (val) => {
    locState.value = val
    // console.log(' val ', val);
});

const {
    childComponent,
    getChildProps,
    getHint,
    getProps,
} = FieldComputed({ props });

onMounted(() => {
    // console.log(' field props.modelValue ', props.modelValue);
});

</script>

<style lang="scss">
@import "style";
</style>
