<template>
    <div class="permissions-guide">

        <acms-v-table
            :columns="{
            'id' : '#',
            'name' : 'Permission name',
            'function' : 'Function',
            'description' : 'Description'

        }"
            :columnsWidth="{
            'id' : '70px',
            'name' : '40%',
            'function' : '30%',
            'description' : '50%'

        }"
            :data="getFilteredCollection"


        >
            <!--            v-model:page="tableForm.page"-->
            <template #header>
                <AcmsVTableHeader v-if="!hideHead"
                                  config="search"
                                  v-model:search="locState.search"

                                  search-placeholder="Search"
                />
                <!--                v-model:entries="tableForm.limit"-->
            </template>
        </acms-v-table>
    </div>
</template>

<script>
export default {
    name: "permissionsGuide"
}
</script>

<script setup="">
import AcmsVTableHeader from "@ui/Table/partials/TableHeader.vue";
import useTableSortable from "@ui/Table/useTableSortable.js";
import {getRoute} from "@plugins/useRoutes.js";
import {computed, reactive} from "vue";

const props = defineProps({
    collection: Object,
    route:String,
    limit:[String,Number],
    hideHead:Boolean,

})

const locState = reactive({
    search:''
})
// const {tableForm} = useTableSortable(
//     props.route, {
//         limit: props.limit ?? 20,
//         search: null,
//         page: 1,
//
//
//     }, {only: ['permissions'], preserveScroll:true, noRequest:true})
//

const getFilteredCollection = computed(()=>{

    if(locState.search){
        return props.collection.filter(item=>item?.name?.toLowerCase().includes(locState.search.toLowerCase()))
    }
    return props.collection
})

</script>
<style scoped>

</style>
