<template>
    <div>
        <h3>GDS Credentials</h3>
        <div class="w-100">
            <template v-for="(item, key) in localItems">

                <div class="gap-3 d-flex w-100">

                    <div class="row w-100">
                        <acms-v-form-field
                            label="System"
                            placeholder="Select options"
                            :modeSelect="{
                             options,
                             outValueKey: 'id',
                            }"
                            name="group"
                            class=" col-4"
                            :model-value="localItems[key].gds_system_id"
                            @update:modelValue="updateFormKey($event,'gds_system_id', key)"
                            :readonly="readonly"
                            :errors="errors?.[`gds_credentials.${key}.gds_system_id`]"
                        />

                        <acms-v-form-field
                            label="ID"
                            name="id"
                            modeInput
                            placeholder="ex. 182187"
                            :model-value="localItems[key].gds_id"
                            @update:modelValue="updateFormKey($event,'gds_id', key)"
                            class=" col-4"
                            type="number"
                            :errors="errors?.[`gds_credentials.${key}.gds_id`]"
                            :readonly="readonly"
                        />

                        <acms-v-form-field
                            label="PCC"
                            name="pcc"
                            modeInput
                            placeholder="ex. 182187"
                            :model-value="localItems[key].pcc"
                            @update:modelValue="updateFormKey($event,'pcc', key)"
                            class=" col-4"
                            type="text"
                            :errors="errors?.[`gds_credentials.${key}.pcc`]"
                            :readonly="readonly"
                        />
                    </div>
                    <div>
                        <acms-v-label/>
                        <acms-v-btn
                            icon="close"
                            class="bg-gray align-self-end   btn-row-action"
                            style="margin-left: 20px;"
                            @click="()=>removeRow(key)"
                            v-if="!readonly"
                        />
                    </div>


                </div>

            </template>


            <acms-v-btn v-if="!readonly"
                title="Add GDS Credentials"
                class="btn-sm btn-outline-primary "
                preset=""
                icon="plus"
                @click="addRow"

            />


        </div>
    </div>

</template>

<script>
export default {
    name: "gdsCredentials"
}
</script>


<script setup="">
import {ref, watch} from "vue";

const emit = defineEmits(['update', 'updatedDeletedIds'])
const props = defineProps({

    items: Array,
    errors: Object,

    options:Array,
    readonly:Boolean,
})


const gdsCredentialRow = {
    gds_system_id: '',
    gds_id: '',
    pcc: '',
}

const localItems = ref(props.items && props.items?.length > 0 ? props.items : [{...gdsCredentialRow}])

const deletedIds = ref([])

const updateFormKey = (value, formKey, key) => {

    localItems.value[key][formKey] = value;
    if(localItems.value[key]?.id){
        localItems.value[key]['__wasChanged'] = true
    }
}

watch(localItems, (val) => {
    emit('update', val)

}, {deep: true})

const addRow = () => {
    localItems.value.push({...gdsCredentialRow});
};

const removeRow = (key) => {

    if(localItems.value.length > 1){
        if (localItems.value[key]?.id) {
            deletedIds.value.push(localItems.value[key].id)
            emit('updatedDeletedIds', deletedIds.value)
        }

        localItems.value.splice(key, 1);
    }
    if(key == 0){
        localItems.value[key] ={
            gds_system_id: '',
            gds_id: '',
            pcc: '',
        }
    }

};


</script>

<style scoped>

</style>
