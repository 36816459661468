<template>


    <div class="acms-v-dropdown dropdown"  v-click-outside="hideDropdown">
        <button type="button"
                class="acms-v-dropdown-toggle btn dropdown-toggle d-flex align-items-center "
                ref="toggleElRef"
                :class="toggleClass"
                @click="showDropdown"
        >
            {{ title }}

            <template v-if="!$slots?.['toggle-right'] && !hideAngle">

                <acms-v-icon class="ms-1" name="angle-down" colr="white"></acms-v-icon>

            </template>


            <slot name="toggle-right"></slot>

        </button>

        <div class="acms-v-dropdown-menu dropdown-menu dropdown-menu-end"

             :class="{show : locState.dropdownShow}"

             ref="listElRef"
        >
            <template v-for="(option, key) in options">

                <button class="acms-v-dropdown-item dropdown-item"
                        :class="optionClass"
                        @click="()=>selectOptionHandle(option)"
                >
                    {{ getTitle(option) }}
                </button>

                <div v-if="option?.divider" role="separator" class="dropdown-divider"></div>
            </template>
        </div>


    </div>


</template>

<script>
export default {
    name: 'AcmsVDropdown',
};
</script>


<script setup="">

import { onMounted, reactive, ref } from 'vue';
import { createPopper } from '@popperjs/core';
import AcmsVIcon from './Icon.vue'

const emit = defineEmits(['select']);

const props = defineProps({
    options: Array,
    title: {
        type: String,
        default: 'Dropdown',
    },
    toggleClass: String,
    optionClass: String,
    placement: {
        type: String,
        default: 'bottom-end'
    },
    hideAngle:Boolean,

});

const toggleElRef = ref()
const listElRef = ref()


const locState = reactive({
    dropdownShow: false,

    popper:null
});

const hideDropdown = () => {
    if(locState.dropdownShow){
        console.log(' 1 ', 1);
        locState.dropdownShow = false;
    }

};
const showDropdown = () => {
    locState.dropdownShow = true;
    locState.popper.update();

};

const getTitle = (option) => {
    return option?.title ?? option;
};

const selectOptionHandle = (option) => {
    emit('select', option);
    locState.dropdownShow = false;
};


onMounted(()=>{

  locState.popper =  createPopper(toggleElRef.value, listElRef.value, {
        placement :  props.placement,
         modifiers: [
          {
              name: 'offset',
              options: {
                  offset: [4, 4],
              },
          },
      ],
    });

})



</script>


<style lang="scss">

.acms-v-dropdown {
    //width: 200px;

    &-toggle {
        //border: 1px solid #CED4DA;
        //border-radius: 4px;
        //background-color: white;
        //padding: 7px 12px;
        //font-size: 16px;
        //line-height: 22px;
        &:after{

         display: none;
        }
    }

    &-menu{
        box-shadow: 0px 0px 24px #D8DEE6;
        border-radius: 8px;
        border: 0;
        padding: 0;
    }
    &-item{
        padding: 12px 12px 12px 20px ; // 28
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }



}


</style>
