import axios from 'axios'


const httpClient = axios.create({

    withCredentials: false, // false is the default
    timeout: 4000
})

export default httpClient

