<template>
    <div class="acms-v-hint"   v-html="getTitle"></div>
</template>

<script>
export default {
    name: 'AcmsVHint',
};
</script>

<script setup>

import { computed } from 'vue';

const props = defineProps({

    errors:[Array, Object, String,], hint:String, hintValid:Boolean, name:String,

});

const getTitle = computed(() => {
    const { errors, hint, hintValid, name } = props;
    if (errors) {

        return Array.isArray(errors) || typeof errors == 'string'
            ? errors
            : Array.isArray(errors[name]) ? errors[name].join(', ') : errors[name];

    } else {
        return hint;
    }

});

</script>

<style lang="scss">

.acms-v-hint{
    display: block;
}

.default-feedback {
    color: #6C757D;
    font-size: .875rem;
    margin-top: 0.375rem;
    width: 100%;
}


</style>
