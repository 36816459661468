import {computed, defineAsyncComponent, reactive, ref} from 'vue';
import {SELECT_VIEW_TEMPLATES} from '@components/ui/FormField/constants';
import selectComputed from '@ui/FormField/elements/Select/select.computed';
import selectWatchers from '@ui/FormField/elements/Select/select.watchers';
import selectHooks from '@ui/FormField/elements/Select/select.hooks';
import {createPopper} from '@popperjs/core';
import {cloneDeep} from 'lodash';


export default function ({props, emit}) {

    const locState = reactive({
        listShow: props.selectListShow ?? false,  //false
        listWasOpened: false,

        tempOptions: props.options ?? null,

        popper: null,

        search: '',
        searchIsProcessing:false,
        searchHasErrors:false,

        value: '',


        focusedOption: null,

    });


    const {
        getOptions,
    }
        = selectComputed({props, locState});

    const toggleElRef = ref();
    const listElRef = ref();
    const searchInputElRef = ref();

    const setTemplateForPartials = () => {
        const {optionTemplate, toggleTemplate, viewTemplate, multiselect, multi} = props;

        const currentTemplate = {
            toggle: viewTemplate ? viewTemplate : toggleTemplate ? toggleTemplate : 'base',
            option: viewTemplate ? viewTemplate : optionTemplate ? optionTemplate : 'base',
        };

        if (multiselect || multi) {
            currentTemplate.toggle = currentTemplate.toggle == 'base' ? 'multi' : currentTemplate.toggle;
            currentTemplate.option = currentTemplate.option == 'base' ? 'multi' : currentTemplate.option;
        }

        const templateVariants = [...SELECT_VIEW_TEMPLATES, 'base', 'multi'];

        // console.log(' currentTemplate.option ', currentTemplate.option);
        const option = currentTemplate.option && templateVariants.includes(currentTemplate.option)
            ? defineAsyncComponent(() => import(`./optionTemplates/${currentTemplate.option}.vue`))
            : false;

        const toggle = currentTemplate.toggle && templateVariants.includes(currentTemplate.toggle)
            ? defineAsyncComponent(() => import(`./toggleTemplates/${currentTemplate.toggle}.vue`))
            : false;

        return {
            option,
            toggle,

            templateName: currentTemplate,

        };

    };

    const {
        isMultiselect,
    }
        = selectComputed({props, locState});

    const updateDropdownPosition = () => {
        locState.popper?.update();
    };



    const createNewPopperWithSettings = (toggle, list) => {
        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({state}) => {
                state.styles.popper.width = `${state.rects.reference.width}px`;
            },
            effect: ({state}) => {
                state.elements.popper.style.width = `${
                    state.elements.reference.offsetWidth
                }px`;
            }
        };

        return createPopper(toggle, list, {
            strategy: 'fixed',
            placement: props.optionsListPlacement,
            modifiers: [
                sameWidth,
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 0,
                        boundary: toggleElRef.value,
                        tether: false, // false by default

                    },
                },
            ],
        });
    }

    const hideListHandle = () => {
        locState.listShow = false;

        locState.focusedOption = null
    };
    const showListHandle = () => {

        if(!props.disabled){
            locState.listShow = true;

            updateDropdownPosition();

            setTimeout(() => {
                locState.popper = createNewPopperWithSettings(toggleElRef.value, listElRef.value)
            }, 0);
        }

    };
    const toggleListHandle = () => {
        if (!locState.listShow) {
            showListHandle();

            setTimeout(() => {
                searchInputElRef?.value?.focus();
            }, 100);

        } else {
            hideListHandle();
        }

    };

    const emitChanges = (value) => {
        setTimeout(() => {
            emit('update:modelValue', value);
            emit('select', value);
        }, 0);

    };

    const removeSelectedTagHandle = (option) => {
        let currentOptions = [...props.modelValue];
        const index = currentOptions.findIndex(item => item == option);
        currentOptions.splice(index, 1);
        emitChanges(currentOptions);
        updateDropdownPosition();
    };

    const selectOptionHandle = (option) => {

        const {viewTemplate, modelValue, limit, outValueKey} = props;
        const updateSimpleOptions = (currentOptions = []) => {
            const index = currentOptions.findIndex(item => item == option);
            if (index > -1) {
                currentOptions.splice(index, 1);
            } else {

                if (!limit || modelValue?.length < Number(limit)) {
                    currentOptions.push(option);
                }

            }
            emitChanges(currentOptions);
        };

        if (isMultiselect.value) {
            let currentOptions = cloneDeep(props.modelValue) ?? [];
            const cloneOptions = cloneDeep(props.options);


            if (viewTemplate == 'multiCheckbox') {
                if (option == 'Any' && !modelValue?.includes('Any')) {
                    emitChanges(cloneOptions);
                } else {
                    updateSimpleOptions(currentOptions);
                }

            } else {
                if (modelValue !== null && Array.isArray(modelValue)) {

                    if (typeof option == 'string' || typeof option == 'number') {

                        updateSimpleOptions(currentOptions);

                    } else if (option?.id) {
                        const index = currentOptions.findIndex(item => item.id == option.id);
                        if (index > -1) {
                            currentOptions.splice(index, 1);
                        } else {
                            currentOptions.push(option);
                        }
                        emitChanges(currentOptions);
                    } else {
                        emitChanges([option]);
                    }

                } else {
                    emitChanges([option]);
                }
            }

            updateDropdownPosition();

        } else {

            if (outValueKey && outValueKey in option) {
                emitChanges(option[outValueKey]);
            } else {
                emitChanges(option);
            }


            hideListHandle();
        }

    };


    const searchInputKeyHandle = (event) => {
        const key = event.key

        let focusedIndex = null

        if (['ArrowUp', 'ArrowDown'].includes(key)) {
            event.preventDefault()

            if (locState.focusedOption) {
                focusedIndex = getOptions.value.findIndex(item => item[props.optionKeyTitle] == locState.focusedOption[props.optionKeyTitle])
            }
        }

        const optionsLength = getOptions.value?.length

        if (key == 'ArrowUp') {

            if (optionsLength > 0) {
                if (locState.focusedOption == null) {
                    locState.focusedOption = getOptions.value[optionsLength - 1]
                } else if (locState.focusedOption) {
                    if (focusedIndex > -1) {
                        if (optionsLength - 1 >= focusedIndex - 1) {
                            if (getOptions.value[focusedIndex - 1]) {
                                locState.focusedOption = getOptions.value[focusedIndex - 1]
                            }
                        }
                    } else {
                        locState.focusedOption = getOptions.value[0]
                    }
                }
            }
        } else if (key == 'ArrowDown') {

            if (optionsLength > 0) {
                if (locState.focusedOption == null) {
                    locState.focusedOption = getOptions.value[0]
                } else if (locState.focusedOption) {
                    if (focusedIndex > -1) {
                        if (focusedIndex + 1 <= optionsLength - 1) {
                            if (getOptions.value[focusedIndex + 1]) {
                                locState.focusedOption = getOptions.value[focusedIndex + 1]
                            } else {
                            }
                        }
                    } else {
                        locState.focusedOption = getOptions.value[0]
                    }
                }
            }

        } else if (key == 'Enter') {

            if (locState.focusedOption) {
                if (focusedIndex > -1) {
                    selectOptionHandle(locState.focusedOption)
                }
            }
        }
    }

    selectWatchers({props, locState});
    selectHooks({
        props, locState,
        computed: {isMultiselect},
        methods: {selectOptionHandle}
    }, {emitChanges});

    return {
        locState, toggleElRef, listElRef,
        searchInputElRef,

        setTemplateForPartials, selectOptionHandle,

        removeSelectedTagHandle, toggleListHandle, hideListHandle,

        searchInputKeyHandle,

    };
}
