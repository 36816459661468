<template>

    <div class="acms-v-form-input-with-dropdown d-flex">
        <template v-if="selectOptions?.length > 0">


            <acms-v-select
                :options="selectOptions"
                viewTemplate="withCode"
                :placeholder="placeholderSelect"
                :search="search"
                :searchAsync="searchAsync"
                :name="uniqueId('code-select')"
                v-model="locState.value[keySelect]"
                :class="{'acms-v-select--focused': locState.inputFocused}"
                :selectedOptionOnInit="selectedOptionOnInit"
                :beforeToggleContent="beforeToggleContent"
                :beforeOptionContent="beforeOptionContent"
                :readonly="readonly"
            >
            </acms-v-select>


        </template>

        <input class="form-control"
               v-bind="$attrs"
               :required="required"
               :disabled="disabled"
               :placeholder="placeholder"
               :name="name"
               :type="type"
               @keydown="onKeydownHandle"
               @change="onKeydownHandle"
               :value="locState.value.text"
               @input="($event)=>onInputHandle($event.target.value)"
               @focus="locState.inputFocused=true"
               @blur="locState.inputFocused=false"
               :readonly="readonly"
        >


        <template v-if="dropdownOptions">
            <button type="button" class="btn dropdown-toggle d-flex align-items-center input-group-text"
                    :class="[classes.dropdownToggle,
                {'focus': locState.inputFocused}
                ]"
                    @click="showDropdown"

            >

                <acms-v-icon class="me-2" :name="getIcon"></acms-v-icon>
            </button>

            <div class="dropdown-menu dropdown-menu-end" v-if="locState.dropdownShow"
                 v-click-outside="hideDropdown"
                 :class="{show: locState.dropdownShow}"
            >
                <template v-for="(option, key) in dropdownOptions">
                    <div v-if="option?.divider" role="separator" class="dropdown-divider"></div>

                    <button class="dropdown-item" v-else-if="option?.canSetMain"
                            :class="classes.mainOption"
                            @click="()=>selectOptionHandle(option)"
                    >{{ getMainOptionTitle(option) }}
                    </button>

                    <button class="dropdown-item" v-else
                            :class="[classes.defaultOption(option), option?.className]"
                            @click="()=>selectOptionHandle(option)"
                    >
                        {{ option.title }}
                    </button>
                </template>
            </div>


        </template>

    </div>



    <!--    </template>-->


</template>

<script>
export default {
    name: 'AcmsVInputWithDropdown',
};
</script>

<script setup>
import { computed, defineAsyncComponent, onBeforeMount, onMounted, reactive, watch } from 'vue';

import {
    defaultAttributesProps,
} from '../field.props';
import { uniqueId } from 'lodash';
import AcmsVIcon from "@ui/Icon.vue";
import AcmsVSelect from "@ui/FormField/elements/Select/Select.vue";

const emit = defineEmits(['update:modelValue', 'clickOnDropdownOption' , 'changeMain']);

const props = defineProps(
    {
        ...defaultAttributesProps,

        dropdownOptions: Array,
        selectOptions:Array,
        keyDropdown:String,
        keySelect:String,


        phoneCode: Boolean,

        modelValue: Object,

        //for select
        placeholderSelect: String,
        search: [Boolean, String, Object],
        searchAsync: String, // url
        selectedOptionOnInit: [String,Number,Object],
        beforeToggleContent:String,
        beforeOptionContent:String,
    },
);

const keyDropdown = props.keyDropdown ?? 'optionDropdown'
const keySelect = props.keySelect ?? 'optionSelect'



const getIcon = computed(() => {

    const optionIcon = locState.value?.[keyDropdown]?.toggleIcon;
    if (optionIcon) {
        locState.toggleIcon = optionIcon;
    }
    return optionIcon ?? locState.toggleIcon;

});

const initValue = () => {

    const defObject = {
        text: '',
        // main: false,
    };
    let result = { ...defObject };

    if(props.dropdownOptions?.length > 0){
        result[keyDropdown] = props.dropdownOptions[0]
    }
    if(!props.selectedOptionOnInit && props.selectOptions?.length > 0){
        result[keySelect] = props.selectOptions[0]
    }

    return result;
};
const onKeydownHandle = (e) => {

        if (e.key == '0' && e.target.value == '') {
            e.preventDefault()
        }

}

const locState = reactive({
    value: null,

    toggleIcon: null,
    dropdownShow: false,

    inputFocused:false,
});

watch(() => props.modelValue, (val) => {
    locState.value = val;
});

const hideDropdown = () => {
    locState.dropdownShow = false;
};
const showDropdown = () => {
    locState.dropdownShow = true;
};

const getMainOptionTitle = (option) => locState.value?.main ? option.title_main : option.title;

const classes = computed(() => {
    return {
        mainOption: locState.value?.main ? 'selected disabled' : '',
        defaultOption: (option) => ({
            selected: option?.title == locState.value?.[keyDropdown]?.title,
        }),
        dropdownToggle: locState.value?.main ? 'btn-primary' : 'btn-dark',
    };
});
const emitUpdatedValue = () => {
    emit('update:modelValue', locState.value);
};

const onInputHandle = (val) => {
    locState.value.text = val;
    emitUpdatedValue();
};

const selectOptionHandle = (option) => {
    // console.log(' option ', option);
    emit('clickOnDropdownOption', option);
    if (option?.canSetMain) {
    } else if (option?.canSelect) {
        locState.value[keyDropdown] = option;
    }

    emitUpdatedValue();

    hideDropdown()
};

onBeforeMount(() => {

    const {modelValue } = props

    locState.value = (modelValue?.[keyDropdown] || modelValue?.[keySelect]) ? modelValue : initValue();


    // if(locState.value?.[keySelect]){
    //     locState.value[keySelect] = props.selectOptions.find(item=>item.id == locState.value?.[keySelect]?.id)
    // }
    // console.log(' locState.value ', locState.value);
});

onMounted(()=>{
    emitUpdatedValue()
})

</script>


<style lang="scss">


.dropdown-toggle{
    &.focus{
        border-color: #4c78dd !important;
    }
}
.acms-v-select[data-v-select-settings="withCode"] {
    .acms-v-select-dropdown {
        width: 320px !important;
    }
}
.acms-v-select--focused{
    .acms-v-select-toggle {
        border-color: #4c78dd !important;
    }
}

.input-group-text {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-divider {
    margin: 0;
}

.dropdown-menu {
    width: 200px;
    box-shadow: 0px 0px 24px rgba(31, 41, 55, 0.1);
    border-radius: 8px;
    padding: 0;

    &.show {
        position: absolute;
        inset: 0px 0px auto auto;
        margin: 0px;
        transform: translate(0px, 40px);
    }
}

//custom


.dropdown-item {
    padding: 8px 8px 8px 28px;
    font-size: 14px;
    line-height: 20px;

    &.selected {
        color: #4C78DD;
        background-position: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='%234C78DD'/%3E%3Cpath d='M11.0295 2.47021C11.3225 2.76318 11.3225 3.23896 11.0295 3.53193L5.02949 9.53193C4.73652 9.8249 4.26074 9.8249 3.96777 9.53193L0.967773 6.53193C0.674805 6.23897 0.674805 5.76318 0.967773 5.47021C1.26074 5.17725 1.73652 5.17725 2.02949 5.47021L4.4998 7.93818L9.97012 2.47021C10.2631 2.17725 10.7389 2.17725 11.0318 2.47021H11.0295Z' fill='black' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        pointer-events: none;
    }

    &.disabled {
        opacity: 0.5;
    }
}

[data-v-form-field="inputWithDropdown"] {
    .acms-v-select {
        margin-right: -1px;
        //width: 86px;
        max-width: max-content;

        &-dropdown {
            width: 400px;
            //max-width: 170px;
            left: 0;
        }

        &-toggle {
            border-radius: 4px 0 0 4px;
        }

        &-toggle-angle {
            //position: absolute;
        }

        & + .form-control {
            border-radius: 0 0 4px 4px;
        }

    }
}


</style>
