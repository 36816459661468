<template>
    <Head title="Add new company - VIP Fares"></Head>
    <AppMainLayout
        title="Add new company"
        :breadcrumbs="[{title:'Company', link: getRoute('companies.index')},  'Add new company']"
    >
        <template #content>
            <div class="bg-white block-content block-content-full container-sm">
                <div class="row">
                    <acms-v-form-field
                        label="Company name"
                        name="title"
                        modeInput
                        placeholder="ex. Apple"
                        v-model="form.title"
                        :errors="form.errors?.title"
                        class=" col-4"
                        required
                    />
                </div>
            </div>
            <acms-v-bottom-actions
                :hide-save-buttons="getDisabled"
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('companies.index'))"
            />
        </template>
    </AppMainLayout>
</template>
<script>
export default {
    name: 'CompanyEditPage',
};
</script>
<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import {computed} from "vue";

const props = defineProps({
    resource: Object,
    message: String,
    status: String,
    actions: {
        type: Array,
        default: () => []
    },
});

const getDisabled = computed(()=>{
    return !props.actions.includes('Update')
})
const AlertsManager = useAlertsManagerStore()

const { title } = props.resource

const form = useForm({
    title
})

const sendFormHandle = (callback = ()=>{}) =>{
    form.put(getRoute('companies.update', props.resource.id),{
        onSuccess(){
            if(props.status == 'error'){
                AlertsManager.add('Server Error', 'error')
            }else{
                AlertsManager.add('Company successfully updated')
            }
            callback?.()
        },
        onError(){
            AlertsManager.add('Company has errors', 'error')
        }
    })
}

const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(()=>router.visit(getRoute('companies.index')))
}

</script>

<style scoped>

</style>
