<template>
    <Head :title="'User Group '+ form.name + ' - VIP Fares'"></Head>
    <AppMainLayout
        :title="form.name"
        :breadcrumbs="[{title:'User Group', link: getRoute('user.groups.index')},  form.name]"
    >
        <template #content>
            <div class="bg-white block-content container-sm">
                <UserGroupPermissionsBox
                    label-group="Name group"
                    groupTypeInput
                    v-model:group="form.name"
                    v-model:permissions="form.permissions"
                    v-model:counter-view="form.contact_view_count"
                    :permissionsGroups="clients"
                    :selectedPermissions="resource.permissions"

                    :errors="form.errors"
                    :readonly="getDisabled"
                    required
                />
            </div>
            <acms-v-bottom-actions
                :hide-save-buttons="getDisabled"
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('user.groups.index'))"
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserGroupEditPage',
};
</script>

<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import UserGroupPermissionsBox from "../../boxes/UserGroupPermissionsBox.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import {computed} from "vue";

const props = defineProps({
    resource: Object,
    message: String,
    status: String,
    clients: Array,
    actions: {
        type: Array,
        default: () => []
    },
});

const AlertsManager = useAlertsManagerStore()

const form = useForm({
    contact_view_count:props.resource?.contact_view_count ?? null,
    ...props.resource
})
const getDisabled = computed(() => {
    return !props.actions.includes('Update')
})
const sendFormHandle = (callback = () => {
}) => {
    form.transform(data => {
        return {
            resource_id: props.resource.id,
            name: data.name,
            contact_view_count: parseInt(data.contact_view_count),
            permissions: Object.values(data.permissions)?.map((item) => item.map(item => item.id))?.flat()
        }
    }).put(getRoute('user.groups.update', props.resource.id), {
        onSuccess() {

            if (props.status == 'error') {
                AlertsManager.add('Server Error', 'error')
            } else {
                AlertsManager.add('User group successfully updated')
            }
            callback()
        }, onError() {
            AlertsManager.add('User group has errors', 'error')
        }
    })
}

const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(() => router.visit(getRoute('user.groups.index')))
}
</script>

<style scoped>

</style>
