<template>
    <Head title="Dashboard - VIP Fares"></Head>
    <AppMainLayout
        title="Dashboard"
    >
        <template #content>
            <div class=" p-1">

            </div>
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'DashboardPage',
};
</script>

<script setup="">
import {Link, router, Head} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";

const props = defineProps({
    collection:Object,
    limit: [String,Number],
    search: String,
    column: String,
    direction: String
});

</script>

<style scoped>

</style>
