<template>

    <div class="acms-v-table-header d-flex mb-4">


            <acms-v-form-field v-if="showBlocks.search"
                mode-input

                v-model="localSearch"
                class="w-100 acms-v-table-search"

                :placeholder="searchPlaceholder"
                hide-hint
                elementClass="bg-gray-light"

            >
                <template #right>
                    <acms-v-icon name="search"/>
                </template>

            </acms-v-form-field>



        <div class="ms-auto d-flex ">
            <div   class="me-2"  v-if="showBlocks.entries">
                <acms-v-select

                    :options="entriesOptions"
                    v-model="localEntries"
                    placeholder="Set count"

                />

            </div>

            <template v-if="showBlocks.add">
                <acms-v-btn v-if="addHref"
                    :title="addTitle"
                    class="btn-primary width-max-content ms-auto"
                    icon-left="plus"
                    :href="addHref"
                />

                <acms-v-btn v-else
                            :title="addTitle"
                            class="btn-primary width-max-content ms-auto"
                            icon-left="plus"
                            @click="$emit('add')"
                />

            </template>


        </div>






        </div>

</template>

<script>
export default {
    name: 'AcmsVTableHeader',
};
</script>

<script setup="">

import { computed, reactive, ref, watch } from 'vue';

const props = defineProps({
    entries:[Number,String],
    entriesOptions:{
        type:Object,
        default:[
            10,20,50,100
        ]
    },
    search:String,
    searchDelay:{
        type:Number,
        default: 300,
    },
    searchPlaceholder:{
        type:String,
        default:'Search'
    },

    addTitle:String,
    addHref:String,

    config:String,
})

const emit = defineEmits(['update:entries', 'update:search', 'add'])

const configSettings = 'search,entries,add'
const showBlocks = computed(()=>{
    const {config} = props
    return {
        search: config?.includes('search'),
        entries: config?.includes('entries'),
        add: config?.includes('add'),
    }
})

const localEntries = ref(props.entries ?? 20)
const localSearch = ref(props.search ?? '')

const locState = reactive({
    entries: props.entries ?? 20,
    search: props.search ?? ''
})
watch(localEntries,(val)=>{
    // console.log(' val ', val);
    emit('update:entries', val)
},  )

let timeSearch
watch(localSearch,(val)=>{
    // console.log(' val ', val);
    clearTimeout(timeSearch)
    timeSearch =   setTimeout( () => {
        emit('update:search', val)
    }, props.searchDelay);

},  )


</script>

<style lang="scss">

.acms-v-table-search{
    max-width: 432px;
}


</style>
