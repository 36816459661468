<template>

    <div class="acms-v-text-editor">

        <QuillEditor theme="snow"
                     :content="modelValue"
                     @update:content="$emit('update:modelValue', $event)"
                     :modules="modules"
                     :toolbar="toolbarOptions"
                contentType="html"

        />


    </div>


</template>

<script>


export default {
    name: 'acms-v-text-editor',
};
</script>
<script setup>

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import ImageUploader from 'quill-image-uploader';

import { onBeforeMount, reactive, ref, watch } from 'vue';

import { defaultAttributesProps } from '@components/ui/FormField/field.props';
import httpClient from '../../../../services/HttpService';

const props = defineProps(
    {
        ...defaultAttributesProps,
        modelValue: String,
        uploadImageUrl: {
            type: String,
            default: 'for-upload-image-need-url',
        },
    });

const locState = reactive({
    content: '',
});

const toolbarOptions = [
    ['image'],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    // [{ 'font': [] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'align': [] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme

    ['clean'],                                         // remove formatting button
];

const modules = {
    name: 'imageUploader',
    module: ImageUploader,
    options: {
        upload: file => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('image', file);

                httpClient.post(props.uploadImageUrl, formData).then(res => {
                    console.log(res);
                    resolve(res.data.url);
                }).catch(err => {
                    reject('Upload failed');
                    console.error('Error:', err);
                });
            });
        },
    },
};

onBeforeMount(() => {


});

</script>

<style lang="scss">

textarea.form-control {
    //min-height: 96px;
    max-height: 70vh;
    padding: 8px 16px;
    //overflow: hidden;
    //resize: none;
}

.acms-v-text-editor {
    display: flex;
    flex-direction: column;

    .ql-editor {
        min-height: 240px;

        p{
            font-size: 16px;
        }
    }
    .ql-toolbar{
        .ql-image{
            svg{
                width: 24px;
                height: 24px !important;
                position: relative;
                 left: -2px;
                 top: -3px;
            }
        }

    }

}
</style>
