<template>


    <div class="acms-v-table-row-actions ms-auto d-flex">

            <div class="me-3 d-flex" v-if="props.actions.includes('email') || props.actions.includes('phone')">

                <acms-v-btn v-if="props.actions.includes('email')" icon="email"
                            class="text-white bg-primary-dark-op p-2 me-2"
                            @click="$emit('clickAction', 'email')"

                />
                <acms-v-btn v-if="props.actions.includes('phone')" icon="phone"
                            class="text-white bg-primary-dark-op p-2"
                            @click="$emit('clickAction', 'phone')"
                />

            </div>

            <div class="d-flex bg-gray-light action-block-rad">
                <acms-v-btn v-if="props.actions.includes('edit')" icon="pen" color="text-primary-darker"
                            class="p-2 bg-gray-light"
                            @click="$emit('clickAction', 'edit')"
                />
                <acms-v-btn v-if="props.actions.includes('show')" icon="eye" color="text-primary-darker"
                            class="p-2 bg-gray-light"
                            @click="$emit('clickAction', 'show')"
                />
                  <template v-if="typeof published !== 'undefined'">
                      <acms-v-btn v-if="props.actions.includes('publish')" :icon=" published ? 'eye' : 'eye-off'" color="text-primary-darker"
                                  class="p-2 bg-gray-light"
                                  @click="$emit('clickAction', 'publish')"
                      />
                  </template>

                <acms-v-btn v-if="props.actions.includes('delete')" icon="close"
                            color="text-primary-darker" class="p-2 bg-gray-light"
                            @click="$emit('clickAction', 'delete')"
                />
            </div>

    </div>


</template>

<script>
export default {
    name: 'AcmsVTableActions',
};
</script>

<script setup="">
const props = defineProps({
    actions: [String,Array],
    published:[Boolean,Number],
});



</script>

<style scoped>

.action-block-rad{
    border-radius: 0.25rem;
}

.acms-v-table-row-actions{
    justify-content: center;
    width: 100%;
}
</style>
