<template>
    <Head title="Users - VIP Fares"></Head>
    <AppMainLayout
        title="Users"
    >
        <template v-slot:header-right>
            <acms-v-btn icon="plus" title="Add new user" class="btn-sm btn-primary ms-auto"
                        v-if="actions.includes('Create')"
                        :href="getRoute('users.create')"/>
        </template>
        <template #content>
            <div class="  ">
                <acms-v-table class="p-4 "
                              :columns="{
                                'id' : '#',
                                'display-name' : 'Display Name',
                                'first-name' : 'Name',
                                'team' : 'Team',
                                'role-name' : 'Groups',
                                'extra-roles' : 'Extra Roles',
                                'actions' : 'Actions',
                                   }"
                              :columns-width="{
                                    'id': '52px',
                                    'display-name' : '240px',
                                    'first-name' : '190px',
                                    'team' : '120px',
                                    'role-name' : '120px',
                                    'extra-roles' : '160px',
                                    'actions': '120px'
                                  }"
                              :columns-cell-class="{
                                'role-name': 'flex-wrap'
                              }"
                              :data="collection.data"
                              :links="collection.links"
                              v-model:page="tableForm.page"
                              sort-keys
                              :actions="getTableActions"
                              key-for-published="active"
                              @update:sort="tableSortHandle"
                              @publishAction="(item)=>router.post(getRoute('users.active', item.id), {id:item.id}, {preserveScroll:true})"
                              @editAction="(item)=>router.get(getRoute('users.edit', item.id))"
                              @deleteAction="(item)=>locState.alert = item"
                >
                    <template #header>
                        <AcmsVTableHeader
                            config="search,entries"
                            v-model:search="tableForm.search"
                            v-model:entries="tableForm.limit"
                            search-placeholder="Search"
                        />
                    </template>
                    <template #bodyCell-id="{item}">
                        <div class="text-end">{{ item.id }}</div>
                    </template>
                    <template #bodyCell-display-name="{item}">
                        <template v-if="actions.includes('Read')">
                            <Link :href="getRoute('users.edit',  item.id  )"
                                  class="text-primary fw-semibold text-capitalize">{{ item.display_name }}
                            </Link>
                        </template>
                        <div v-else
                             class="text-primary fw-semibold text-capitalize">{{ item.display_name }}
                        </div>
                    </template>
                    <template #bodyCell-role-name="{item}">
                        <template v-for="rol in item.roles">
                            <acms-v-badge class="mx-1 mb-1" v-if="rol?.name" :title="rol.name" :project="rol?.client_name"/>
                        </template>
                    </template>
                </acms-v-table>
                <AlertDelete
                    v-model:show="locState.alert"
                    type="user"
                    @delete="deleteRow"
                />
            </div>
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserIndexPage',
};
</script>

<script setup="">
import {computed, reactive} from 'vue';
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';
import {Link, router, Head} from '@inertiajs/vue3'
import {getRoute} from "@plugins/useRoutes.js";
import useTableSortable from "@ui/Table/useTableSortable.js";
import AlertDelete from "@components/app/alerts/AlertDelete.vue";

const props = defineProps({
    collection: Object,
    limit: [String, Number],
    search: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },

});


const {tableForm, tableSortHandle} = useTableSortable(
    getRoute('users.index'), {
        limit: props.limit ?? 10,
        search: props.search,
        column: props.column,
        direction: props.direction,

    })

const getTableActions = computed(() => {
    const arr = []
    if (props.actions.includes('Read')) {
        arr.push('edit')
    }
    if (props.actions.includes('Update')) {
        arr.push('publish')
    }
    if (props.actions.includes('Delete')) {
        arr.push('delete')
    }
    return arr
})


const locState = reactive({
    alert: null,
})

const deleteRow = () => {
    const item = locState.alert
    router.delete(getRoute('users.destroy', item.id), {
        onFinish() {
            locState.alert = null
        }
    })
}
</script>

<style scoped>

</style>
