<template>
    <Head title="Add new department - VIP Fares"></Head>
    <AppMainLayout
        title="Add new department"
        :breadcrumbs="[{title:'Department', link: getRoute('departments.index')},  'Add new department']"
    >
        <template #content>
            <div class="bg-white block-content block-content-full container-sm">
                <div class="row">
                    <acms-v-form-field
                        label="Department name"
                        name="title"
                        modeInput
                        placeholder="ex. Marketing"
                        v-model="form.title"
                        :errors="form.errors?.title"
                        class=" col-4"
                        required
                    />
                </div>
            </div>
            <acms-v-bottom-actions
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('departments.index'))"
            />
        </template>
    </AppMainLayout>
</template>
<script>
export default {
    name: 'DepartmentCreatePage',
};
</script>
<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";

const props = defineProps({tags: Array});

const AlertsManager = useAlertsManagerStore()

const form = useForm({
    title: null,
})

const sendFormHandle = (callback = () => {}) => {
    form.post(getRoute('departments.store'), {
        onSuccess() {
            if (props.status == 'error') {
                AlertsManager.add('Server Error', 'error')
            } else {
                AlertsManager.add('Department successfully created')
            }

            callback?.()
        },
        onError() {
            AlertsManager.add('Department has errors', 'error')
        }
    })
}

const saveHandle = () => {
    sendFormHandle()
}
const saveAndExitHandle = () => {
    sendFormHandle(() => router.visit(getRoute('departments.index')))
}

</script>

<style scoped>

</style>
