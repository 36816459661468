import {defineStore} from 'pinia'
import {generateUUID} from "@/helpers/stringHelpers";


export const useAlertsManagerStore = defineStore('alertsManager', {

    state: () => ({
        list: [
            // {
            //     type:'success',
            //     content:'success'
            // },
            // {
            //     type:'danger',
            //     content:'error'
            // }
        ]
    }),

    actions: {

        add(content = '', type ='success'){
            this.push({
                content, type
            })
        },

        push(item) {
            const currentLength = this.list.length
            if(currentLength > 4){
                this.list.splice(5, 1)
            }
            const id = generateUUID()

            this.list.unshift({...item, id})

            setTimeout( () => {
                const index = this.list.findIndex(item=> item.id == id)
                if(index > -1){
                    this.list.splice(index, 1)
                }
            }, 3000);

        },

        remove(index){
            this.list.splice(index, 1)
        }

    },
})
