<template>
    <Head title="IP Security - VIP Fares"></Head>
    <AppMainLayout
        title="IP Security"
    >
        <template v-slot:header-right>
            <acms-v-btn icon="plus" title="Add new IP" class="btn-sm btn-primary ms-auto"
                        v-if="actions.includes('Create')"
                        :href="getRoute('ip.security.create')"/>
        </template>
        <template #content>
            <acms-v-tabs
                :items="{
                'ipList':'IP list',
                'settings': 'Settings'
                }"
                content-class="p-0"
                @afterChangeTab="afterChangeTabHandle"
            >
                <template #tab-ipList>
                    <acms-v-table class="p-4 "
                                  :columns="{
                                'id' : '#',
                                'ip-address' : 'IP',
                                'description' : 'description',
                                'tag' : 'Tag',
                                'status' : 'Status',
                                'actions' : 'Actions',
                                   }"
                                  :columns-width="{
                                    'id': '52px',
                                    'ip-address' : '240px',
                                    'description' : '190px',
                                    'tag' : '160px',
                                    'status' : '160px',
                                    'actions': '120px'
                                  }"
                                  :data="collection.data"
                                  :links="collection.links"
                                  v-model:page="tableForm.page"
                                  sortKeys
                                  :actions="getTableActions"
                                  @update:sort="tableSortHandle"
                                  @editAction="(item)=>router.get(getRoute('ip.security.edit', item.id))"
                                  @deleteAction="(item) => deleteHandle(item)"
                    >
                        <template #header>
                            <AcmsVTableHeader
                                config="search,entries"
                                v-model:search="tableForm.search"
                                v-model:entries="tableForm.limit"
                                search-placeholder="Search"
                            />
                        </template>
                        <template #bodyCell-id="{item}">
                            <div class="text-end">{{ item.id }}</div>
                        </template>
                        <template #bodyCell-ip-address="{item}">

                            <template v-if="actions.includes('Read')">
                                <Link :href="getRoute('ip.security.edit',  item.id  )"
                                      class="text-primary fw-semibold text-uppercase">
                                    {{ item.ip_address }}
                                </Link>
                            </template>
                            <div v-else
                                 class="text-primary fw-semibold text-uppercase">{{ item.ip_address }}
                            </div>
                        </template>
                        <template #bodyCell-status="{item}">
                            <acms-v-badge :title="item?.status == 1 ? 'Allowed'  : 'Forbidden'"
                                          :class="item?.status == 1 ? 'bg-success' : 'bg-danger'"/>
                        </template>
                    </acms-v-table>
                </template>
                <template #tab-settings>
                    <div class="p-4">
                        <acms-v-form-field
                            label="Access mode"
                            v-model="settingsForm.access_mode"
                            name="tag"
                            :modeSelect="['Default', 'Whitelist', 'Blacklist']"
                            :errors="settingsForm?.errors.access_mode"
                            class="col-6 "
                            :readonly="!actions.includes('Update')"
                        />
                    </div>
                </template>
            </acms-v-tabs>
            <acms-v-bottom-actions
                v-if="activeTab == 'settings' && settingsForm.isDirty && actions.includes('Update')"
                @save="saveHandle"
                hide-cancel
                hide-save-and-exit
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'IpSecurityIndexPage',
};
</script>

<script setup="">

import {computed, onMounted, ref} from 'vue';
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';
import {Head, Link, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import useTableSortable from "@ui/Table/useTableSortable.js";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";

const AlertsManager = useAlertsManagerStore()
const props = defineProps({
    collection: Object,
    status: String,
    access_mode: String,

    limit: [String, Number],
    search: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },
});

const activeTab = ref('ipList')
const afterChangeTabHandle = key => {
    activeTab.value = key
}

const getTableActions = computed(() => {
    const arr = []
    if (props.actions.includes('Read')) {
        arr.push('edit')
    }
    if (props.actions.includes('Update')) {
        arr.push('publish')
    }
    if (props.actions.includes('Delete')) {
        arr.push('delete')
    }
    return arr
})

const {tableForm, tableSortHandle} = useTableSortable(
    getRoute('ip.security.index'), {
        limit: props.limit ?? 20,
        search: props.search ?? '',
        column: props.column ?? null,
        direction: props.direction ?? null
    })
const settingsForm = useForm({
    access_mode: props.access_mode ?? 'Default',
})

const saveHandle = () => {
    settingsForm.post(getRoute('ip.security.access.mode'), {
        preserveScroll: true,
        onSuccess() {
            if (props.status == 'error') {
                AlertsManager.add('Server Error', 'error')
            } else {
                AlertsManager.add('Settings successfully saved')
            }
        },
        onError() {
            AlertsManager.add('Settings has errors', 'error')
        }
    })
}

const deleteHandle = (item) => {
    if (confirm('Are you sure you want to delete this IP address?')) {
        router.delete(getRoute('ip.security.destroy', item.id), {
            onSuccess() {
                AlertsManager.add('IP address successfully deleted', 'success')
            },
            onError() {
                AlertsManager.add('Failed to delete IP address', 'error')
            }
        })
    }
}
</script>

<style scoped>

</style>
