import { MODES, MODES_ADDITIONS,  SELECT_VIEW_TEMPLATES } from '@components/ui/FormField/constants';

export const defaultAttributesProps = {
    modelValue: [Number, String, Boolean, Array, Object],
    // value: [Number, String, Boolean, Array, Object],
    name: {
        type: String,
        // required: true,
    },

    label: String,
    required:Boolean,
    placeholder: String,
    style: Object,
    disabled: Boolean,
    readonly: Boolean,
    inert: Boolean,
    type: {
        type: String,
        default: 'text',
    },



};

export const modesProps = {

    /// remove or not remove
    mode:{
        type:String,
        validator(value) {
            return MODES.list.includes(value)
        }
    },
    modeAdditions: {
        type: String,
    },


    //
    withIcon:Boolean,

    modeDatepicker: {
        type: [Boolean,Object],
    },
    modeTimePicker: {
        type:  [Boolean,Object],
    },
    modeInputWithDropdown: {
        type: [Boolean,Object],
    },
    modeQuantity: {
        type: [Boolean,Object],
    },
    modeSelect: {
        type:  [Boolean,Object, Array],
    },
    modeInput: {
        type: Boolean,
    },
    modeTextarea: {
        type: [Boolean,Object],
    },
    modeTextEditor: {
        type: [Boolean,Object],
    },


    modeSwitcher: {
        type: [String,Boolean, Object],
    },

    //




};








const feedbackProps = {
    errors: [Object,Array,String],
    hint:{
        type: String,
        default:null
    },
    hintValid:{
        type:Boolean,
        default:false
    },
    hintWarning:{
        type:Boolean,
        default:false
    },
    hideHint:Boolean,

    hintIcon:String,
    hintIconClass:String,
    hintIconSize:String,
}

const classNames = {
    parentClass:String,
    wrapClass:String,
    elementClass:String,


}

export const iconProps = {
    icon: String,
    iconSize: String,
    iconClass: String,
}

///

export const  customProps = {
    //option or options
    singleOption: {
        type: Object,
    },
    //switch
    switchSingle: {
        type: Boolean,
    },



// fieldNameInErrorMessage: {
    //     type: String,
    // },
    // rootClass: {
    //     type: String,
    // },

};

const fieldProps = {
    ...defaultAttributesProps,
    ...modesProps,

    ...iconProps,
    ...customProps,


    ...classNames,
    ...feedbackProps,


    debug:Boolean,
};
export default fieldProps;


