<template>

    <div class="acms-v-table  "

         :class="{'can-drag-table':  tableDrag.canDrag}"
    >


        <template v-if="$slots?.header">
            <slot name="header"></slot>
        </template>
        <div v-if="$slots?.['header-content']" class="acms-v-table-header">
            <slot name="header-content"></slot>
        </div>


        <div class="acms-v-table-content table-responsive" :class="contentClass"
             v-on="tableContentEvents"
             ref="tableContentElRef"

        >

            <table class="table table-striped table-vcenter " :class="tableClass">
                <thead v-if="!hideHead">
                <tr

                >
                    <template v-if="showNumber">
                        <th class="text-center" :style="{'width':columnsWidth?.['#'] ?? '52px'}">#</th>
                    </template>
                    <template v-for="column in getColumns">

                        <th :class="column.class" :style="{'width':column.width}">

                            <acms-v-table-column-title
                                :sort="sortState"
                                @update:sort="(e)=>sortHandle(e, column.key.replaceAll('-','_'))"
                                :isSortable="canSortColumn(column.key)"
                                :class="getColumnTitleClass(column)"
                                :columnKey="column.key.replaceAll('-','_')"
                            >
                                <template v-if="!$slots['headCell-'+column.key]">
                                    {{ column.title }}
                                </template>

                                <slot :key="column.key" :title="column.title" :name="'headCell-'+column.key"></slot>

                            </acms-v-table-column-title>

                        </th>

                    </template>

                </tr>
                </thead>
                <tbody class="acms-v-table-body">

                <tr v-for="(rowItem, rowIndex) in getData"
                    v-on="getTableEventsByKey('row', {rowItem, rowIndex})"
                    :class="[bodyRowClass, dynamicRowClass?.(rowItem), {'acms-v-table--row-unpublished': typeof rowItem[keyForPublished] !== 'undefined' && !rowItem[keyForPublished]}]"
                >
                    <template v-if="showNumber">
                        <td class="text-center"
                            :class="[columnsClass?.['#']]" :style="{'width':columnsWidth?.['#'] ?? '52px'}">
                            <div class="acms-v-table-body-cell"></div>
                            {{ rowIndex + 1 }}
                        </td>
                    </template>

                    <td v-for="(column, columnIndex) in  getColumns" :style="{'width':column.width}"


                    >

                        <div class="acms-v-table-body-cell d-flex " :class="column.cellClass">
                            <template v-if="rowItem?.parent_id && column.key.includes('code') ">
                                <div class="table-cell-ico-child "></div>
                            </template>

                            <template v-if="column.key == 'actions' && !$slots['bodyCell-actions']">
                                <acms-v-table-actions
                                    @clickAction="$emit($event+'Action', rowItem)"
                                    :actions="actionsShowFun?.(rowItem) ?? actions"
                                    :published="rowItem?.[keyForPublished]"
                                />
                            </template>
                            <template v-else-if="!$slots['bodyCell-'+column.key.replaceAll('_','-')] && columnIndex !== 7">
                                {{ rowItem[column.key?.replaceAll('-', '_') ?? column.key] }}
                            </template>
                            <slot :columnKey="column.key" :index="rowIndex" :item="rowItem"
                                  :name="'bodyCell-'+column.key"></slot>
                        </div>

                    </td>

                </tr>

                </tbody>
            </table>
        </div>

        <div class="acms-v-table-footer">

            <slot name="footer"></slot>


            <AcmsVTablePagination v-if="links?.length > 3"
                                  :links="links"
                                  :page="page"
                                  @update:page="$emit('update:page', $event)"
            />


        </div>

    </div>

</template>

<script>
export default {
    name: 'AcmsVTable',
};
</script>


<script setup>

import {computed,  reactive, ref} from 'vue';


import AcmsVTableColumnTitle from './partials/TableColumnTitle.vue';
import AcmsVTablePagination from "./partials/TablePagination.vue";
import AcmsVTableActions from './partials/TableActions.vue';

const emit = defineEmits(['update:sort', 'clickRow', 'editAction', 'deleteAction', 'publishAction','update:page']);

const props = defineProps({

    data: {
        type: Array,
        default: () => ([]),
    },
    links: Array,

    columns: {
        type: Object,
        default: () => ({
            'key': 'title',
        }),
    },
    columnsWidth: {
        type: Object,
        default: () => {
        },
    },
    columnsClass: {
        type: Object,
        default: () => {
        },
    },
    columnsInput: [Array, String],

    bodyRowClass: String,
    columnsTitleClass: {
        type: Object,
        default: () => ({}),
    },
    columnsCellClass: {
        type: Object,
        default: () => ({}),
    },
    sortKeys: {
        type: [Array, String, Boolean],
    },

    tableClass: String,

    showNumber: Boolean,

    contentClass: String,

    actions: [String,Array],
    actionsShowFun: Function,

    hideHead: Boolean,

    eventsList: [Array, String],

    horizontalScroll: Boolean,

    dynamicRowClass: Function,

    keyForPublished: {
        type: String,
        default: 'published'
    },

    page: [Number, String],

});


const getSortColumns = computed(() => {

    let columns = {...props.columns}
    if (columns?.actions) {
        delete columns.actions
    }

    return columns

})



const getTableEventsByKey = (key, params) => {

    if (key == 'row' && props.eventsList?.includes('row')) {
        return {
            click(e) {
                emit('clickRow', params);
            },
        };
    }
    return {};
};


const canSortColumn = (key) => {
    const sortKeys = props.sortKeys
    if (typeof sortKeys == 'string') {
        if (sortKeys.length > 1) {
            return sortKeys?.includes(key)
        } else {
            return Object.keys(getSortColumns.value).includes(key)
        }
    }
}


const getColumns = computed(() => {
    const {columns, columnsClass, columnsWidth, columnsCellClass, columnsTitleClass} = props;

    return Object.entries(columns).map((item) => {

        const [key, title] = item;
        return {
            key,
            title,
            class: columnsClass?.[key] ?? '',
            width: columnsWidth?.[key] ?? '',
            titleClass: columnsTitleClass?.[key] ?? '',
            cellClass: columnsCellClass?.[key] ?? '',
        };
    });

});

const getData = computed(() => {
    return props.data;
});

const tableContentElRef = ref();

const sortState = ref({column: '', direction: null});

const sortHandle = (sortDirection, key) => {
    sortState.value = {
        column: sortDirection != null ? key : null,
        direction: sortDirection
    };
    emit('update:sort', sortState.value);
};

const getColumnTitleClass = (column) => {

    const {key, titleClass} = column;
    if (key == 'actions') {
        return titleClass != '' ? titleClass : 'justify-content-center';
    }

    return titleClass;

};

let mousedownTime;

const tableDrag = reactive({
    canDrag: false,
    posX: null,
    posLeft: null,
    scrollLeft: null,
});

const mousedownHandle = (e) => {
    clearTimeout(mousedownTime);
    mousedownTime = setTimeout(() => {

        if (window.getSelection().type == 'Caret') {
            tableDrag.canDrag = true;
            tableDrag.posX = e.clientX;
            tableDrag.posLeft = e.target.scrollLeft;
            tableDrag.scrollLeft = tableContentElRef.value.scrollLeft;
        }

    }, 400);
};

const cancelTableDrag = () => {
    tableDrag.scrollLeft = null;
    clearTimeout(mousedownTime);
    tableDrag.canDrag = false;
};

const mouseupHandle = (e) => {
    cancelTableDrag();
};

const mouseleaveHandle = () => {
    cancelTableDrag();
};

const mousemoveHandle = (e) => {
    if (tableDrag.canDrag) {
        const dx = tableDrag.posX - e.clientX;
        if (tableDrag.scrollLeft > 0) {
            tableContentElRef.value.scrollLeft = tableDrag.scrollLeft - (e.clientX - tableDrag.posX);
        } else {
            tableContentElRef.value.scrollLeft = dx - tableDrag.posLeft;
        }
    }
};

const scrollHandle = (e) => {
    const tableEl = tableContentElRef.value;
    if (tableEl.scrollWidth > tableEl.clientWidth) {
        e.preventDefault();
        tableContentElRef.value.scrollLeft = tableEl.scrollLeft + e.deltaY;
    }
};


const tableContentEvents = {
    mousedown: mousedownHandle,
    mouseup: mouseupHandle,
    mouseleave: mouseleaveHandle,
    mousemove: mousemoveHandle,
    wheel: props.horizontalScroll ? scrollHandle : () => {
    }
}

</script>


<style lang="scss">
@import "@scssVars";

$icon-child: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 2.12402C1.5 1.70918 1.16484 1.37402 0.75 1.37402C0.335156 1.37402 0 1.70918 0 2.12402L0 4.37402C0 5.61621 1.00781 6.62402 2.25 6.62402H9.43828L7.71797 8.34434C7.425 8.63731 7.425 9.11309 7.71797 9.40606C8.01094 9.69902 8.48672 9.69902 8.77969 9.40606L11.7797 6.40605C12.0727 6.11309 12.0727 5.6373 11.7797 5.34434L8.77969 2.34434C8.48672 2.05137 8.01094 2.05137 7.71797 2.34434C7.425 2.6373 7.425 3.11309 7.71797 3.40605L9.43828 5.12402H2.25C1.83516 5.12402 1.5 4.78887 1.5 4.37402L1.5 2.12402Z' fill='%238093AD'/%3E%3C/svg%3E%0A");

.table-cell-ico-child {
    background-image: $icon-child;
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    align-self: center;
    margin-right: 8px;
}

table thead th {
    color: #6C757D;
}

.table {
    margin-bottom: 0;
}

table td {

    font-size: 14px;
    line-height: 20px;
}

.acms-v-table {
    background-color: #fff;
    border-radius: 5px;
    &.can-drag-table {
        cursor: grabbing;
        user-select: none;
    }
}

.acms-v-table--row-unpublished {
    background-color: $bg-danger-light;

    td {
        box-shadow: unset;
    }
}

.dark-mode {

}


</style>
