<template>

    <div class="acms-v-datepicker"
         :class="[{'acms-v-datepicker--range': datesRange},
                {'opened': locState.opened},
                {'acms-v-datepicker--readonly': readonly}
         ]"

    >


        <VueDatePicker
            v-model="locState.value"
            :format="format"
            ref="dp"
            v-bind="getProps"
            :placeholder="placeholder"
            :inline="inline"
            :enable-time-picker="timePicker"
            @update:model-value="$emit('submitDate')"
            @open="locState.opened = true"
            @closed="locState.opened = false"



        >

            <template #trigger v-if="$slots?.trigger">
                <slot name="trigger"></slot>
            </template>


            <template #calendar-header="{ index, day }">

                {{ weekDayNames[index] }}

            </template>


            <template #action-row="{ closePicker, selectDate }">
                <div class="action-row d-flex w-100">
                    <button class="close-button ms-auto" @click="()=> [selectDate(), closePicker()]">{{submitText}}</button>
                </div>
            </template>






        </VueDatePicker>
    </div>


    <!--        <input class="form-control"-->
    <!--               :required="required"-->
    <!--               :name="name"-->
    <!--               :value="modelValue"-->
    <!--               @input="$emit('update:modelValue', $event.target.value)"-->

    <!--        >-->
</template>

<script>
export default {
    name: 'AcmsVDatepicker',
};
</script>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import fieldProps, { defaultAttributesProps } from '../field.props';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const emit = defineEmits(['update:modelValue', 'submitDate']);

const weekDayNames = {
    0: 'Mon',
    1: 'Tue',
    2: 'Wed',
    3: 'Thu',
    4: 'Fri',
    5: 'Sat',
    6: 'Sun',
};

const format = (date) => {

    let result = '';
    let dates = Array.isArray(date) ? date : [date];

    dates.forEach(item => {
        const day = item.getDate();
        const month = item.getMonth() + 1;
        const year = item.getFullYear();

        let dateString = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;

        if (result.length > 0) {
            result = result + ' - ' + dateString;
        } else {
            result = dateString;
        }

    });

    return result;
};

const props = defineProps({
    ...defaultAttributesProps,
    datesRange:Boolean,
    modelValue: [String, Number, Array, Date],
    inline:Boolean,
    submitText:{
        type:String,
        default: 'Done'
    },

    timePicker:Boolean,

    dayClass:Function,
    readonly:Boolean,

});

const dp = ref();
const locState = reactive({
    value: new Date(),

    opened:false,
});

const getProps = computed(() => {
    const { datesRange, dayClass } = props;

    return {
        'multi-calendars': datesRange,
        'range': datesRange,
        'day-class': dayClass
    };


});






watch(() => locState.value, (val) => {

    emit('update:modelValue', val);
});
onMounted(() => {

    if (props.datesRange) {
        const startDate = new Date();
        const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
        locState.value = Array.isArray(props.modelValue) ? props.modelValue : [startDate, endDate];

    } else {
        locState.value = props.modelValue ?? null;
    }

});

</script>


<style lang="scss">

$blue: #4C78DD;
$calendar-icon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.45601 3.12504H12.5453V1.69275C12.5453 1.2183 12.9235 0.833374 13.3935 0.833374C13.8636 0.833374 14.2417 1.2183 14.2417 1.69275V3.12504H15.6554C16.903 3.12504 17.9173 4.15092 17.9173 5.41671V16.875C17.9173 18.139 16.903 19.1667 15.6554 19.1667H4.34589C3.09654 19.1667 2.08398 18.139 2.08398 16.875V5.41671C2.08398 4.15092 3.09654 3.12504 4.34589 3.12504H5.75958V1.69275C5.75958 1.2183 6.13774 0.833374 6.60779 0.833374C7.07785 0.833374 7.45601 1.2183 7.45601 1.69275V3.12504ZM3.78041 16.875C3.78041 17.1901 4.03346 17.448 4.34589 17.448H15.6554C15.9664 17.448 16.2209 17.1901 16.2209 16.875V7.70837H3.78041V16.875Z' fill='%234B5563' fill-opacity='0.8'/%3E%3C/svg%3E%0A");



.acms-v-datepicker {
    & > * {
        font-family: 'Inter', sans-serif;
    }

    &.acms-v-datepicker--readonly{
        .dp__input{
            background-color: #e9ecef!important;
        }
    }
    .dp__btn, button {
        font-family: 'Inter', sans-serif;
    }

    .dp__menu {
        //min-width: 320px;

        //left: 0 !important;
        //transform: unset !important;
        //top: 40px !important;
    }

    .dp__arrow_top {
        display: none;
    }

    .dp__main {
        //max-width: 320px;
    }

    .dp__calendar {
        & > [aria-label="Calendar wrapper"] {
            display: flex;
            flex-direction: column;
        }
    }

    .dp__input {
        padding: 8px 12px;
        height: 38px;
        font-size: 16px;
        line-height: 22px;
        background-color: transparent;
    }

    .dp__menu_inner {
        padding-bottom: 0;
    }

    .dp__month_year_select {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #000;
    }

    .dp__input_icon {
        right: 0;
        left: unset;
        background-image: $calendar-icon;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        color: transparent;
        background-position: center;
    }

    .dp__cell_inner {
        font-size: 14px;
        line-height: 22px;
        padding: 6px;
        width: 36px;
        height: 36px;
    }

    .dp__today {
        border: 0;
        color: $blue;
        font-weight: 700;
        font-size: 16px;

        &.dp__active_date, &.dp__range_start {
            color: #fff;
        }
    }

    .dp__range_start, .dp__range_end {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            background-color: #f3f3f3;
            z-index: -1;
        }
    }

    .dp__range_start {
        border-radius: 50%;
        &:after{
          border-radius: 50% 0 0 50%;
            right: -1px;
        }
    }

    .dp__range_end {
        border-radius: 50%;

        &:after{
            border-radius:  0  50%  50% 0  ;
            left: -1px;
        }
    }

    .dp__calendar_row {
        padding-right: 12px;
        padding-left: 12px;
    }

    .dp__month_year_row {
        border-bottom: 1px solid #E9ECEF;
    }

    .dp__calendar_header {
        padding: 0 12px;

    }

    .dp__calendar_header_item {
        color: #6C757D;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        justify-content: center;
        align-items: center;
        display: flex;
        //margin-right: 8px;
        padding: 12px;
        height: unset;
    }

    .dp__date_hover_end:hover, .dp__date_hover_start:hover, .dp__date_hover:hover {
        border-radius: 50%;
    }


    .dp__active_date {
        border-radius: 50%;
        background-color: $blue;
    }

    .dp__action_row {
        padding-top: 0px;
    }

    .close-button {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background-color: $blue;
        border-radius: 4px;
        color: #fff;
        border: none;
        padding: 5px 8px;
    }

    .dp__calendar {
        .dp__calendar_header_separator {
            display: none;
        }
    }


    .dp__clear_icon {
        display: none;
    }

}


.acms-v-datepicker--range{
    min-width: 250px;
}





</style>
