export const obscureEmail = email => {
    const [name, domain] = email.split('@');
    return `${name[0]}${new Array(name ? name.length : 10).join('*')}@${domain}`;
};

export const obscurePhone = phone => {
    const strArr = phone.split('');
    const newStrArr = strArr.map((item, index) => {
        return index > 5 ? '*' : item
    });
    return newStrArr.join('')
};

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx'.replace(/[xy]/g, function (c) {
        let v, r
        r = Math.random() * 16 | 0;
        v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}
