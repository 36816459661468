import {computed} from 'vue';
import {camelCase} from 'lodash';
import {toggleProps, optionProps} from './select.props';
import {selectUtils} from './select.utils';
import debounce from "@libs/debounce.js";

export default function SelectComputed({props, locState}) {


    const isMultiselect = computed(() => {
        const {multi, multiselect, multiple} = props;
        return multi || multiselect || multiple;
    });

    const getToggleProps = computed(() => {
        return selectUtils.retrieveSelectedProps(props, toggleProps);
    });

    const getOptionProps = computed(() => {

        return selectUtils.retrieveSelectedProps(props, optionProps);
    });

    const getPlaceholder = computed(() => {

        const {placeholderSelect, placeholder} = props;

        return placeholderSelect ? placeholderSelect : placeholder;
    });


    const getOptions = computed(() => {
                const {optionKeyTitle, searchAsync, search,options} = props;

                return locState?.tempOptions ?? options

                // if(isMultiselect){
                //     return  tempOptions
                // }



            }
        )
    ;

    const getSelected = computed(() => {
        const {modelValue, selected, optionKeyTitle, subOptionKeyTitle, options, optionKeyValue} = props;


        if (typeof modelValue === 'string' || typeof modelValue === 'number') {

            const options = getOptions.value
            if (options && options?.length > 0) {
                if (options[0]?.[optionKeyValue] != undefined) {
                    const find = options.find(item => item[optionKeyValue] == modelValue)
                    if (find) {
                        return find?.[optionKeyTitle]
                    }
                }
            }

            return (modelValue.length > 0 || !isNaN(modelValue)) ? modelValue : null;
        }

        if (typeof modelValue === 'boolean') {
            const find = options.find(item => item[optionKeyValue] == (modelValue ? '1' : '0'))
            if (find) {
                return find?.[optionKeyTitle]
            }
        }

        if (Array.isArray(modelValue)) {
            return modelValue.length > 0 ? modelValue : '';
        }

        if (selected?.subOption) {
            return `${selected.option[optionKeyTitle]} (${selected.subOption?.[subOptionKeyTitle]})`;
        }

        return modelValue?.[optionKeyTitle] ?? null;

    });

    return {
        getToggleProps,
        getOptionProps,
        getPlaceholder,
        getOptions,
        getSelected,

        isMultiselect,
    };
}
