<template>

    <div class="acms-v-badge badge" :class="getClass" :style="getStyle">
        {{ title }}
    </div>

</template>

<script>
export default {
    name: 'AcmsVBadge',

};
</script>


<script setup>

import { computed } from 'vue';

const DEFAULT_COLORS = ['primary']

const DEFAULT_BADGES = {
    'new prospect': 'btn-alt-primary',
    'outgoing': 'btn-alt-primary',

    'passenger' : 'btn-alt-primary',
    'sales agent' : 'btn-primary',

    'co-passenger': 'bg-secondary',
    'contact': 'bg-secondary',

    'return prospect': 'bg-primary',
    'primary': 'bg-primary',

    'new client' : 'btn-alt-info',
    'tickets' : 'btn-alt-info',
    'accounting' : 'btn-info',

    'vip client': 'bg-success',
    'support manager': 'bg-success',
    'incoming': 'bg-success-light text-success'
}


const props = defineProps({
    title: String,
    color: String,
    project: String,
});

const getClass = computed(() => {
    const { color, title, project } = props;

    if(project){
      switch (project) {
        case 'CRM':
          return 'bg-info';
        case 'Passport':
          return 'bg-danger';
        case 'Infohub':
          return 'bg-secondary';
        case 'Back Office':
          return 'bg-success';
        default:
          return 'bg-primary';
      }
    }

    if(title){
        return DEFAULT_BADGES?.[title?.toLowerCase() ?? null] ?? 'bg-primary'
    }

    return  'bg-primary'

});


const getStyle  =computed(()=>{
    const { color } = props;
    return color && (color.includes('#') || color.includes('rgb')) ? {'background-color': color} : '';
})


</script>

<style lang="scss">

.acms-v-badge{
    font-size: 14px;
    line-height: 20px;
    padding: 2px 6px;
}

</style>
