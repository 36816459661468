import { SELECT_VIEW_TEMPLATES } from '@components/ui/FormField/constants';

export const selectProps = {
    selectListShow:Boolean,

    placeholderSelect:String,
    placeholder: {
        type:String,
        default:'Select option'
    },

    selectedOptionOnInit:[Number,String,Object],

    options: {
        type: [Array, Object],
    },
    optionKeyValue: {
        type: String,
        default: 'id',
    },
    optionKeyTitle: {
        type: String,
        default: 'title',
    },
    optionKeyArray: {
        type: String,
        default: 'array',
    },

    subOptionKeyTitle: {
        type: String,
        default: 'title',
    },

    toggleTagKeyTitle: {
        type: [String,Array],
        default: 'title',
    },

    outValueKey:String,

    viewTemplate:{
        type:String,
        validator(value) {
            return SELECT_VIEW_TEMPLATES.includes(value)
        }
    },
    optionTemplate:{
        type:String,
        validator(value) {
            return SELECT_VIEW_TEMPLATES.includes(value)
        }
    },
    toggleTemplate:{
        type:String,
        validator(value) {
            return SELECT_VIEW_TEMPLATES.includes(value)
        }
    },

    search: [Boolean, String, Object],

    searchPlaceholder: String,
    searchAsync:String, // url
    searchAsyncParams:Object,

    optionsListPlacement:{
        type:String,
        default:'bottom'
    },

    searchKey:String, // ???        ?????


    multiselect: Boolean,
    multi: Boolean,
    multiple: Boolean,

    limit: [Number, String],

    //for options
    beforeOptionContent:String,
    beforeToggleContent:String,
    orderSelectedOptions:Boolean,

}



export const toggleProps = {
    selected: [Object, String, Number, Boolean],
    modelValue: [Object, String, Number, Boolean],
    options:Array,

    placeholder: String,

    optionKeyTitle: String,
    optionKeyValue: String,
    subOptionKeyTitle: {
        type: String,
        default: 'title',
    },

    toggleTagKeyTitle: {
        type: [String,Array],
        default: 'title',
    },

    beforeToggleContent:String,
};

export const optionProps = {
    option: [Object, String, Number, Boolean],

    selected: [Object, String, Number, Boolean],
    modelValue: [Object, String, Number, Boolean],

    multiselect: Boolean,
    multi: Boolean,
    optionIndex: [String, Number],

    optionKeyValue: {
        type: String,
        default: 'id',
    },
    optionKeyTitle: {
        type: String,
        default: 'title',
    },
    subOptionKeyTitle: {
        type: String,
        default: 'title',
    },
    optionKeyArray: {
        type: String,
        default: 'array',
    },
    focusedOption:[String,Object],

    orderSelectedOptions:Boolean,

    beforeOptionContent:[String, Object]
}
