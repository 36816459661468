<template>

    <div class="acms-v-popover" @mouseleave="mouseleaveHandle">

        <div class="acms-v-popover-trigger" ref="triggerElRef" @mouseover="mouseoverHandle">
            <slot name="default"></slot>
        </div>

        <Transition name="fade">
            <div class="acms-v-popover-body" v-show="locState.show" ref="bodyElRef" :class="[bodyClass]">

                <div class="acms-v-popover-content">
                    <slot name="content"></slot>

                </div>

                <template v-if="$slots?.footer">
                    <div class="acms-v-popover-footer d-flex justify-content-end">

                        <slot name="footer"></slot>

                    </div>
                </template>

                <div class="acms-v-popover-arrow"
                     data-popper-arrow></div>

            </div>
        </Transition>

    </div>


</template>

<script>
export default {
    name: 'AcmsVPopover',
};
</script>


<script setup="">

import { computed, onMounted, reactive, ref } from 'vue';
import { createPopper } from '@popperjs/core';

const props = defineProps({
    bodyClass: String,
    placement: {
        type: String,
        default: 'bottom-start',
        validator: function (value) {
            return [
                'auto',
                'auto-start',
                'auto-end',
                'top',
                'top-start',
                'top-end',
                'bottom',
                'bottom-start',
                'bottom-end',
                'right',
                'right-start',
                'right-end',
                'left',
                'left-start',
                'left-end',
            ].includes(value);
        },
    },
});

const locState = reactive({
    show: false,

    popover: null,
});

const getBodyClass = computed(() => {
    return {
        show: locState.show,
        active: locState.popover
    }
});

const triggerElRef = ref('');
const bodyElRef = ref('');

const mouseoverHandle = () => {

    if (!locState.popover) {
        locState.popover = createPopper(triggerElRef.value, bodyElRef.value, {
            placement: props.placement,
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        });
    }
    setTimeout(() => {
        locState.show = true;
        locState.popover.update()
    }, 0);

};
const mouseleaveHandle = () => {
    locState.show = false;
};

onMounted(() => {

    // createPopper(triggerElRef.value, bodyElRef.value, {
    //     placement: props.placement,
    //     modifiers: [
    //         {
    //             name: 'offset',
    //             options: {
    //                 offset: [0, 8],
    //             },
    //         },
    //     ],
    // });

});


</script>

<style lang="scss">

.acms-v-popover {
    $this: &;

    &-body {
        padding: 16px 20px;
        max-width: 350px;
        border-radius: 5px;
        background-color: #1F2937 !important;


        &:after {
            content: "";
            position: absolute;
        }
        //&.active{
        //    display: block;
        //}
        //
        //&.show {
        //    opacity: 1;
        //}

        &[data-popper-placement^='top'] {

            &:after {
                left: 0;
                right: 0;
                height: 10px;
                bottom: -10px
            }

            #{$this}-arrow {
                left: -3px !important;
                bottom: 4px;
            }
        }

        &[data-popper-placement^='bottom'] #{$this}-arrow {
            top: -4px;
            left: -3px !important;
        }

        &[data-popper-placement^='bottom-start'] {
            &:after {
                left: 0;
                right: 0;
                height: 10px;
                top: -10px
            }

            #{$this}-arrow {
                top: -4px !important;
                //left: -3px ;
            }
        }

        &[data-popper-placement^='left'] #{$this}-arrow {
            right: 4px;
            top: -3px !important;
        }

        &[data-popper-placement^='right'] #{$this}-arrow {
            left: -4px;
            top: -3px !important;
        }

    }
}


.acms-v-popover-arrow {

    &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: #1F2937;
        visibility: visible;
        content: '';
        transform: rotate(45deg);

    }

}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
