<template>
    <Head title="Add new user group - VIP Fares"></Head>
    <AppMainLayout
        title="Add new user group"
        :breadcrumbs="[{title:'User Group', link: getRoute('user.groups.index')},  'Add new user group']"
    >
        <template #content>
            <div class="bg-white block-content container-sm">
                <UserGroupPermissionsBox
                    label-group="Name group"
                    groupTypeInput
                    v-model:group="form.name"
                    v-model:permissions="form.permissions"
                    v-model:counter-view="form.contact_view_count"
                    :permissionsGroups="clients"
                    :errors="form.errors"
                    required
                />
            </div>
            <acms-v-bottom-actions
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('user.groups.index'))"
            />
        </template>
    </AppMainLayout>
</template>

<script>

export default {
    name: 'UserGroupCreatePage',
};
</script>

<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import UserGroupPermissionsBox from "../../boxes/UserGroupPermissionsBox.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";

const AlertsManager = useAlertsManagerStore()
const props = defineProps({
    message: String,
    status: String,
    clients:Array,
});
const form = useForm({
    name: null,
    permissions: null,
    contact_view_count: null,
})

const sendFormHandle = (callback = () => {}) => {
    form.transform(data=>{
        return {
            ...data,
            contact_view_count: parseInt(data.contact_view_count),
            permissions: Object.values(data.permissions)?.map((item)=>item.map(item=>item.id))?.flat()
        }
    }).post(getRoute('user.groups.store'), {
        onSuccess() {

            if (props.status == 'error') {
                AlertsManager.add('Server Error', 'error')
            } else {
                AlertsManager.add('User group successfully created')
            }
            callback()
        },onError(){
            AlertsManager.add('User group has errors', 'error')
        }
    })
}

const saveHandle = () => sendFormHandle()

const saveAndExitHandle = () => {
    sendFormHandle(() => router.visit(getRoute('user.groups.index')))
}
</script>

<style scoped>

</style>
