import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';

const PiniaStore = createPinia();

import './styles/index.scss';
import 'virtual:svg-icons-register';

import { directiveClickOutside, directiveTooltip } from '@/directives';
import appComponents from './app.components';
import AppMainLayout from '@layouts/AppMainLayout.vue';
import { getRoute, pluginGetRoute } from '@plugins/useRoutes';


createInertiaApp({
    resolve: name => {

        const pages =
            import.meta.glob('./components/app/pages/**/*.vue', { eager: true });
        let page = pages[`./components/app/pages/${name}.vue`];
        // page.default.layout = page.default.layout || AppMainLayout;
        return page;

        // return pages[`./components/app/pages/${name}.vue`];
    },
    setup ({ el, App, props, plugin }) {
        let app = createApp({

                render: () => h(App, props),
                mounted () {


                },

            },
        ).use(plugin).use(PiniaStore).use(pluginGetRoute)

        .directive('click-outside', directiveClickOutside)
        .directive('tooltip', directiveTooltip)

        app.config.globalProperties.getRoute = getRoute

        app = appComponents(app, ['layout','ui', 'pages']);


        app.mount(el);
    },
});

// app.mount('#main-app');

