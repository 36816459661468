<template>
    <Head title="Add new team - VIP Fares"></Head>
    <AppMainLayout
        title="Add new team"
        :breadcrumbs="[{title:'Team', link: getRoute('teams.index')},  'Add new team']"
    >
        <template #content>
            <div class="bg-white block-content block-content-full container-sm">
                <div class="row">
                    <acms-v-form-field
                        label="Team name"
                        name="title"
                        modeInput
                        placeholder="ex. Team name"
                        v-model="form.title"
                        :errors="form.errors?.title"
                        class=" col-4"
                        required
                    />
                    <acms-v-form-field
                        :mode-select="{
                                multi: true,
                                search: true,
                                searchPlaceholder: 'search',
                                options: companies,
                                optionKeyTitle: 'title',
                                toggleTagKeyTitle: 'title'
                        }"
                        label="Companies"
                        name="company_ids"
                        placeholder="Add companies separated by comma/space"
                        v-model="form.company_ids"
                        :errors="form.errors?.company_ids"
                        required
                    />
                    <acms-v-form-field
                        label="Department"
                        name="department_id"
                        :modeSelect="{
                            options: departments,
                            outValueKey:'id'
                        }"
                        v-model="form.department_id"
                        :errors="form.errors?.department_id"
                        class=" col-4"
                        required
                    />
                    <acms-v-form-field
                        label="Manager"
                        name="manager_id"
                        :modeSelect="{
                            options: managers,
                            outValueKey:'id'
                        }"
                        v-model="form.manager_id"
                        :errors="form.errors?.manager_id"
                        class=" col-4"
                    />
                </div>
            </div>
            <acms-v-bottom-actions
                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('teams.index'))"
            />
        </template>
    </AppMainLayout>
</template>
<script>
export default {
    name: 'TeamCreatePage',
};
</script>
<script setup="">
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import {getRoute} from "@plugins/useRoutes.js";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";

const props = defineProps({
    companies: Array,
    departments: Array,
    managers: Array,
});

const AlertsManager = useAlertsManagerStore()

const form = useForm({
    title: null,
    company_ids: [],
    department_id: null,
    manager_id: null,
})

const sendFormHandle = (callback = () => {}) => {
    form.transform((data)=>{
        return {
            ...data,
            company_ids: data.company_ids.map((item)=>item.id) ?? null,
        }
    })
    .post(getRoute('teams.store'), {
        onSuccess() {
            if (props.status === 'error') {
                AlertsManager.add('Server Error', 'error')
            } else {
                AlertsManager.add('Team successfully created')
            }

            callback?.()
        },
        onError() {
            AlertsManager.add('Team has errors', 'error')
        }
    })
}

const saveHandle = () => {
    sendFormHandle()
}
const saveAndExitHandle = () => {
    sendFormHandle(() => router.visit(getRoute('teams.index')))
}

</script>

<style scoped>

</style>
