<template>

    <div class="acms-v-multi-row-crud pb-4">

        <div class="acms-v-multi-row-crud-list mb-4">

            <template v-for="(item, key) in locState.rowsList">


                <div class="acms-v-multi-row-crud-item" :class="getItemClass(item)">

                    <TransitionGroup name="fade">
                        <template v-if="item._rowStatus=='opened'" :key="key">

                            <div class="acms-v-multi-row-crud-item-opened  ">
                                <div class="acms-v-multi-row-crud-item-actions ms-auto d-flex">

                                    <!--                                    <acms-v-btn icon="check" color="text-primary-darker" class="p-2 bg-gray-light"-->
                                    <!--                                                @click="()=>saveRow(key)"-->
                                    <!--                                    />-->
                                </div>


                                <slot name="opened" :item="item" :key="key" :itemErrors="itemErrors">

                                </slot>

                                <acms-v-btn
                                    class="btn-primary ms-auto me-auto"
                                    v-if="item?.id"
                                    title="Update"
                                    @click="()=>saveRow(key)"
                                />

                            </div>

                        </template>


                        <template v-if="item._rowStatus=='closed'" :key="key">

                            <div class="acms-v-multi-row-crud-item-closed  d-flex align-items-center  ">

                                <slot name="closed" :key="key" :number="key+1" :item="item">

                                </slot>

                                <div class="acms-v-multi-row-crud-item-actions ms-auto d-flex">
                                    <acms-v-btn icon="pen" color="text-primary-darker" class="p-2 bg-gray-light"
                                                @click="()=>editRow(key)"
                                    />
                                    <acms-v-btn icon="close" color="text-primary-darker" class="p-2 bg-gray-light"
                                                @click="()=>removeRow(key)"
                                    />
                                </div>
                            </div>
                        </template>
                    </TransitionGroup>
                </div>

            </template>

        </div>


        <acms-v-btn
            :title="addTitle"
            class="btn-outline-primary   ms-auto me-auto"
            icon="plus"
            @click="addRow"
        />


    </div>


</template>

<script>
export default {
    name: 'AcmsVMultiRowCrud',
};
</script>

<script setup="">

import { computed, onBeforeMount, reactive, watch } from 'vue';
import _ from 'lodash';

const emit = defineEmits(['update', 'removeItem', 'add', 'closeItem']);

const props = defineProps({

    modelValue: Array,
    items: Array,
    addTitle: String,

    itemSample: Object,
    itemErrors: Object,

    emptyState: Boolean,

    triggerSaveOpenedRow: Boolean,

});

const prepareRow = (item) => {

    if (item?._rowStatus) {
        return item;
    } else {
        item._rowStatus = 'opened';
        return item;
    }

};

const prepareRowsOnInit = () => {
    if (!props.emptyState) {
        return props.items?.length ? props.items.map(prepareRow) : [prepareRow(getSample())];
    }
    return [];
};

const locState = reactive({
    rowsList: [],
});

const getItemClass = (item) => {
    return {
        'opened': item._rowStatus == 'opened',
        'closed': item._rowStatus == 'closed',
    };
};

const getSample = () => {
    return _.cloneDeep(props.itemSample);
};

watch(props.items, (itemsVal, prevVal) => {

        locState.rowsList = itemsVal;

    },
);

const emitUpdate = (item, key) => {
    emit('update', locState.rowsList);
};

const addRow = () => {

     if(locState.rowsList?.length  > 0){
         locState.rowsList.forEach((item, index) => {
             if (item._rowStatus != 'closed') {
                 // item._rowStatus = 'closed';
                 console.log(' item ', item);
                 emit('closeItem', { item, index },
                     {
                         closeRow: () => item._rowStatus = 'closed',
                         appendRow: () => locState.rowsList.push(prepareRow(getSample())),
                     });
             }
         });
     }else{
         locState.rowsList.push(prepareRow(getSample()))
     }


    emitUpdate();
};

const editRow = (index) => {

    locState.rowsList = locState.rowsList.map(item => {
        item._rowStatus = 'closed';
        return item;
    });

    locState.rowsList[index]._rowStatus = 'opened';
    emitUpdate();
};
const removeRow = (index) => {
    emit('removeItem',
        {
            item: locState.rowsList[index],
            index,
        },
        { removeRow: () => locState.rowsList.splice(index, 1) },
    );

    emitUpdate();

};
const saveRow = (index, close = true) => {
    // if (close) {
    //     locState.rowsList[index]._rowStatus = 'closed';
    // }

    emitUpdate();

    emit('closeItem', { item: locState.rowsList[index], index }, {
        closeRow: () => locState.rowsList[index]._rowStatus = 'closed',
        appendRow:()=> {}
    });

};

watch(() => props.triggerSaveOpenedRow, (triggered) => {
        if (triggered) {
            locState.rowsList.forEach((item, index) => {
                if (item._rowStatus == 'opened') {
                    saveRow(index, false);
                }
            });
        }
    },
);

onBeforeMount(() => {

    locState.rowsList = prepareRowsOnInit();
});


</script>

<style lang="scss">

.acms-v-multi-row-crud-item {
    padding: 24px 0;
    margin: 0 20px;

    &.closed {
        border-bottom: 1px solid #CED4DA;
    }
}

.acms-v-multi-row-crud-item-closed {

}

.acms-v-multi-row-crud-item-opened {


    position: relative;

    .acms-v-multi-row-crud-item-actions {
        position: absolute;
        right: 0;
    }
}


</style>
