<template>
    <div id="page-container"
         class="sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed main-content-narrow side-trans-enabled"
         :class="[
            {'page-header-dark dark-mode': locState.darkModeActive},
            {'sidebar-mini': locState.sidebarMini}
            ]">
        <div id="page-overlay"></div>
        <nav id="sidebar" class="d-flex flex-column" aria-label="Main Navigation">
            <div class="content-header">
                <Link class="fw-semibold text-dual" :href="getRoute('dashboard')">
                      <span class="smini-visible">
                        <i class="fa fa-circle-notch text-primary"></i>
                      </span>
                    <span class="smini-hide fs-5 tracking-wider">Passport</span>
                </Link>
                <div>
                    <button type="button" class="btn btn-sm btn-alt-secondary" data-toggle="layout"
                            data-action="dark_mode_toggle"
                            @click="toggleDarkMode"
                    >
                        <i class="far fa-moon"></i>
                    </button>
                    <a class="d-lg-none btn btn-sm btn-alt-secondary ms-1" data-toggle="layout"
                       data-action="sidebar_close"
                       href="javascript:void(0)">
                        <i class="fa fa-fw fa-times"></i>
                    </a>
                </div>
            </div>
            <div class="app-main-layout-sidebar">
                <div class="sidebar-user">
                    <div class="sidebar-user-avatar">
                        <img
                            :src="getAuthUser.avatar ?? '../images/auth-user-default-img.svg'"
                            alt="">
                    </div>
                    <div class="sidebar-user-name">{{ getAuthUser.name }}</div>
                    <div class="sidebar-user-role flex-wrap d-flex justify-content-center"
                         :class="getRoleColor(getAuthUser.group)">
                        <acms-v-badge class="mx-1 mb-1" :title="getAuthUser.group.name" :project="'Passport'"></acms-v-badge>
                    </div>
                </div>
                <div class="content-side  ">
                    <ul class="nav-main">
                        <template v-for="item in menuList">

                            <li class="nav-main-item  " v-if="item.allowed"  :class="{'open': isOpen }">
                                <Link class="nav-main-link"
                                      :class="getActive(item.title)"
                                      :href="item.link?? '#'"
                                      v-if="!item?.dropDown"
                                >
                                    <acms-v-icon class="nav-main-link-icon" :name="item.icon"></acms-v-icon>
                                    <span class="nav-main-link-name"> {{ item.title }}</span>
                                </Link>
                                <a class="nav-main-link"
                                   v-else
                                   :class="{
                                        'active': $page.props.global.currentUri.includes(item.url),
                                        'nav-main-link-submenu': item.dropDown
                                    }"
                                   @click.prevent="openDropDown"
                                   :href="`${ item.url }`">
                                    <acms-v-icon class="nav-main-link-icon" :name="item.icon"></acms-v-icon>
                                    <span class="nav-main-link-name">{{ item.title }}</span>
                                </a>
                                <ul v-if="item?.dropDown" class="nav-main-submenu">
                                    <template v-for="subitem in item.dropDown">
                                        <li class="nav-main-item"
                                            :class="{'open': isOpen }"
                                            v-if="subitem.allowed"
                                        >
                                            <Link class="nav-main-link"
                                                  :class="getActive(subitem.title)"
                                                  :href="subitem.link?? '#'"
                                            >
                                                <span class="nav-main-link-name"> {{ subitem.title }}</span>
                                            </Link>
                                        </li>
                                    </template>

                                </ul>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="sidebar-footer mt-auto  ">
                <a href="#" class="nav-main-link" @click="showLogoutModal">
                    <acms-v-icon class="nav-main-link-icon" name="arrow-right-to-bracket"></acms-v-icon>
                    <span class="nav-main-link-name"> LogOut  </span>
                </a>
                <div class="sidebar-app-version pt-4 pb-4 content-side ">
                    <span> VIPFares v1.0.0</span>
                </div>
            </div>
        </nav>
        <header id="page-header">
            <div class="content-header">
                <acms-v-btn icon="collapse" icon-size="20" class="btn-alt-secondary"
                            style="padding: 6px"
                            :iconColor="locState.darkModeActive ? 'text-white' :''"
                            @click="locState.sidebarMini = !locState.sidebarMini"></acms-v-btn>
                <div class="ms-auto">
                    <slot name="header-right"></slot>
                </div>
            </div>
            <div id="page-header-search" class="overlay-header bg-body-extra-light">
                <div class="content-header">
                    <form class="w-100" action="be_pages_generic_search.html" method="POST">
                        <div class="input-group">
                            <button type="button" class="btn btn-alt-danger" data-toggle="layout"
                                    data-action="header_search_off">
                                <i class="fa fa-fw fa-times-circle"></i>
                            </button>
                            <input type="text" class="form-control" placeholder="Search or hit ESC.."
                                   id="page-header-search-input" name="page-header-search-input">
                        </div>
                    </form>
                </div>
            </div>
            <div id="page-header-loader" class="overlay-header bg-body-extra-light">
                <div class="content-header">
                    <div class="w-100 text-center">
                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </div>
        </header>
        <main id="main-container">
            <div class="bg-body-light">
                <div
                    class="content  p-4  d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center content  ">
                    <div class="flex-grow-1">
                        <h3 class=" lh-base fw-bold   mb-0">
                            {{ title ?? 'Page title' }}
                        </h3>
                    </div>
                    <template v-if="breadcrumbs">
                        <nav class="flex-shrink-0 mt-3 mt-sm-0 ms-sm-3" aria-label="breadcrumb">
                            <ol class="breadcrumb breadcrumb-alt">
                                <li class="breadcrumb-item">
                                    <Link class="link-fx" :href="breadcrumbs?.[0].link ?? '#'">
                                        {{ breadcrumbs?.[0].title ?? 'Breadcrumbs' }}
                                    </Link>
                                </li>
                                <li class="breadcrumb-item" aria-current="page">
                                    {{ breadcrumbs?.[1] ?? 'Breadcrumbs' }}
                                </li>
                            </ol>
                        </nav>
                    </template>
                </div>
            </div>
<!--            {{ $page.props.sidebarAllowedModules }}-->
            <div class="app-main-layout-container content" id="main-app">
                <slot name="content"></slot>
            </div>
            <div class="app-main-layout-alerts-manager">
                <transition-group name="slide">
                    <template v-for="(item,index) in AlertsManager.list" :key="index">
                        <acms-v-alert

                            :content="item.content"
                            :type="item.type"
                            @remove="AlertsManager.remove(index)"
                        />
                    </template>
                </transition-group>
            </div>
        </main>
        <acms-v-modal-alert
            v-model:show="locState.logoutModal"
            title="Log Out"
            text="Are you sure you want to logout?"
            leftButtonTitle="Cancel"
            rightButtonClass="btn-danger"
            rightButtonTitle="Yes, logout"
            @leftButtonClick="locState.logoutModal = false"
            @rightButtonClick="logoutHandle"
        />
    </div>
</template>
<script>export default {name: 'AppMainLayout',};</script>
<script setup="">
import {Link, usePage} from '@inertiajs/vue3';
import {reactive, onBeforeMount, ref, computed} from 'vue';
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import LocalStorageService from "@services/LocalStorageService";
import {getRoute} from "@plugins/useRoutes.js";

const props = defineProps({title: String, breadcrumbs: Array});
const $page = usePage()
const AlertsManager = useAlertsManagerStore()

const isOpen = ref(false);

const openDropDown = () => {
    isOpen.value = !isOpen.value;
};

const locState = reactive({
    darkModeActive: false,
    sidebarMini: false,
    logoutModal: false,
});

const toggleDarkMode = () => {
    locState.darkModeActive = !locState.darkModeActive;
    LocalStorageService.set('AcmsDarkMode', locState.darkModeActive)
};

const getAuthUser = computed(() => {
    return $page.props.auth_user
})

const menuList = ref([
    {
        title: 'Users',
        link: getRoute('users.index'),
        icon: 'users',
        allowed: $page.props.sidebarAllowedModules?.users
    },
    {
        title: 'User Group',
        link: getRoute('user.groups.index'),
        icon: 'users-cog',
        allowed: $page.props.sidebarAllowedModules?.groups
    },
    {
        title: 'Permission',
        link: getRoute('user.permissions.index'),
        icon: 'user-lock',
        allowed: $page.props.sidebarAllowedModules?.permissions
    },
    {
        title: 'IP Security',
        link: getRoute('ip.security.index'),
        icon: 'server',
        allowed: $page.props.sidebarAllowedModules?.ip_security
    },
    {
        title: 'Teams',
        link: '#',
        icon: 'teams',
        allowed: $page.props.sidebarAllowedModules?.team || $page.props.sidebarAllowedModules?.department || $page.props.sidebarAllowedModules?.company,
        dropDown: [
            {
                title: 'Teams',
                link: getRoute('teams.index'),
                allowed: $page.props.sidebarAllowedModules?.team
            },
            {
                title: 'Departments',
                link: getRoute('departments.index'),
                allowed: $page.props.sidebarAllowedModules?.department
            },
            {
                title: 'Companies',
                link: getRoute('companies.index'),
                allowed: $page.props.sidebarAllowedModules?.company
            },
        ]
    },
])

const getActive = (title = '') => {
    const currentUri = $page.props.global.currentUri
    return {active: currentUri.includes(title.toLowerCase().replaceAll(' ', '-'))}
}

const getRoleColor = (item) => {
    const object = {
        'Passport': 'bg-danger',
        'CRM':'bg-info',
        'Infohub':'bg-secondary',
        'Back Office': 'bg-success',
    }
    if(item &&  item?.client_name && object?.[item.client_name]){
        return  object[item.client_name]
    }
}
const showLogoutModal = () => {
    locState.logoutModal = true
}
const logoutHandle = () => {
    locState.logoutModal = false
    window.history.replaceState(false, false, getRoute('auth.login',));
    window.location.href = getRoute('auth.logout',)
}
onBeforeMount(() => {
    locState.darkModeActive = LocalStorageService.get('AcmsDarkMode') ?? false

    menuList.value.forEach(item => {
        if (item?.dropDown) {
            if (item.dropDown.some(item => $page.props.global.currentUri.includes(item.link))) {
                isOpen.value = true;
            }
        }
    })

})
</script>

<style lang="scss">

#page-header .content-header {
    padding: 0 24px;
}
.app-main-layout-container {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 24px;
}
.app-main-layout-header {
    height: 100px;
}
.app-main-layout-sidebar {
    .content-side {
        padding-top: 0;
    }
    .sidebar-user {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: #19212C;
        &-avatar {
            border-radius: 50%;
            border: 5px solid #525C69;

            overflow: hidden;
            margin-bottom: 16px;
            img {
                width: 64px;
                height: 64px;
                object-fit: cover;
            }
        }
        &-name {
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 8px;
        }
        &-role {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 2px 6px;
            color: #F8D4D4;
            border-radius: 4px;
        }
    }
    .nav-main-link.active {
        background-color: #E9ECEF !important;
        &:hover {
            background-color: #E9ECEF !important;
        }
        span {
            color: #1F2937;
        }
        .nav-main-link-icon {
            color: #1F2937 !important;
        }
    }
}
.app-main-layout-content {
    width: 100%;
}
.app-main-layout-alerts-manager {
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 10000;
    width: 360px;
}

.nav-main-submenu{
    padding-left: 0;
}
.nav-main-submenu .nav-main-link {
    padding-left: 2.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
//.app-main-layout-sidebar .nav-main-submenu .nav-main-link.active,
//.app-main-layout-sidebar .nav-main-submenu .nav-main-link.active:focus {
//    background-color: #1F2937 !important;
//    &:hover {
//        span {
//            color: #1F2937 !important;
//        }
//    }
//}

//.nav-main-dark .nav-main-link:focus, .nav-main-dark .nav-main-link:hover,
//.page-header-dark #page-header .nav-main-link:focus, .page-header-dark #page-header .nav-main-link:hover,
//.sidebar-dark #sidebar .nav-main-link:focus, .sidebar-dark #sidebar .nav-main-link:hover {
//    background-color: #1F2937;
//    span {
//        color: #1F2937 !important;
//    }
//}

.app-main-layout-sidebar .nav-main-submenu .nav-main-link.active span,
.app-main-layout-sidebar .nav-main-submenu .nav-main-link.active:focus span{
    color: white !important;
}

.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link, .page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link, .sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link
{
    background-color: transparent !important;
}


</style>
