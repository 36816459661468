import {watch} from 'vue';
import httpClient from '@/services/HttpService';
import {getRoute} from "@plugins/useRoutes";
import debounce from "@libs/debounce.js";

export default function ({props, locState}) {

    const {searchAsync, search, options, optionKeyTitle} = props;



    let timeoutToSearch;
    watch(() => locState.search, (val) => {

        if(options && search && !searchAsync){
            if ( locState.search.length > 1) {
                let searchString = locState.search
                if (typeof search == "object") {
                    const {keys: searchKeys, excludeSymbols} = search
                    if (searchKeys) {
                        debounce(()=>{
                            if (excludeSymbols) {
                                excludeSymbols.forEach((symbol) => {
                                    searchString = searchString.replaceAll(symbol, '')
                                })
                            }
                            locState.tempOptions = options.filter(item => {
                                return searchKeys.some(key => {
                                    return String(item[key])?.toLowerCase().includes(searchString.toLowerCase());
                                })

                            })
                        }, 300)

                    }
                }

                locState.tempOptions = options.filter(item => {
                    return (typeof options[0] == "string" ? item : item[optionKeyTitle])?.toLowerCase().includes(searchString.toLowerCase());
                });
            }else{
                locState.tempOptions = options
            }
        }



        if (searchAsync && searchAsync.length > 0) {


            // console.log(' getRoute(searchAsync) ', getRoute(searchAsync));
            if (locState.search.length > 2) {
                locState.searchIsProcessing = true
                locState.searchHasErrors = false

                clearTimeout(timeoutToSearch);

                timeoutToSearch = setTimeout(() => {
                    let params = {search: val}

                    if(props.searchAsyncParams){
                        params = {...params,...props.searchAsyncParams}
                    }

                    httpClient.get(getRoute(searchAsync),
                        {params}).then(({data}) => {
                        if (data) {
                            locState.tempOptions = data;
                        }
                    })
                        .catch(() => {
                            locState.searchHasErrors = true
                        })

                        .finally(() => {
                            locState.searchIsProcessing = false
                        });
                }, 300);
            }

        }
    });

}
