<template>
    <acms-v-modal
        :show="show"
        @hide="$emit('update:show', false)"
        size="small"
        :title="title"
        footerClass="modal-alert-footer"
        class="acms-v-modal-alert"
    >
        <template #content>
            {{text}}
        </template>
        <template #footer-buttons="{hideHandle}">
            <button class="btn btn-sm me-2"
                    :class="leftButtonClass"
                    @click="$emit('leftButtonClick')"
            >{{leftButtonTitle}}
            </button>
            <button class="btn btn-sm"
                    :class="rightButtonClass"
                    @click="$emit('rightButtonClick')"

            >{{rightButtonTitle}}
            </button>

        </template>
    </acms-v-modal>

</template>

<script>
export default {
    name: "AcmsVModalAlert"
}
</script>

<script setup="">
const props = defineProps({
    show: Boolean,
    title: String,
    text:String,
    leftButtonClass:{
        type:String,
        default: 'bg-white border-primary-dark'
    },

    leftButtonTitle:{
        type:String,
        default: 'Cancel'
    },
    rightButtonTitle:{
        type:String,
        default: 'Cancel'
    },

    rightButtonClass:{
        type:String,
        default: 'btn-primary'
    },
})
const emit = defineEmits(['leftButtonClick', 'rightButtonClick','update:show'])

</script>

<style  >

.acms-v-modal-alert{
    .modal-alert-footer{
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .acms-v-modal-content{
        font-weight: 400;
        font-size: 14px;
    }
}
</style>
