<template>


    <acms-v-modal-alert
        :show="Boolean(show)"
        @update:show="$emit('update:show', $event)"
        :title="getTitleAndText.title"
        :text="getTitleAndText.text"
        leftButtonTitle="Cancel"
        rightButtonTitle="Yes, delete"
        right-button-class="btn-danger"
        @leftButtonClick="$emit('update:show', null)"
        @rightButtonClick="$emit('delete', show)"
    />


</template>

<script>
export default {
    name: "AlertDelete",
}
</script>


<script setup="">



import {computed} from "vue";

const TITLE_AND_TEXTS = {
    'user': {
        title: 'Delete user',
        text: 'All information about this user will be deleted'
    },
    'userGroup': {
        title: 'Delete user group',
        text: 'All information about this user group will be deleted'
    },

}

const props = defineProps({
    show: [Boolean,Array,Object],
    type: {
        type: String,
        validator(value) {
            return ['user', 'userGroup'].includes(value)
        },
        default:'request'
    }
})


const getTitleAndText = computed(()=>{
    return TITLE_AND_TEXTS[props.type] ?? {title:'', text:''}
})

</script>
<style scoped>

</style>
