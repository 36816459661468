export const getRoute = (name = 'index', params = undefined) => {

    const routes = window.__AppRoutes;

    if (routes && name in routes) {
        if (Array.isArray(params)) {
            const [value, key = 'id'] = params;
            const link = routes[name].replace(`{${key}}`, value);
            return link;
        }
        else if (typeof  params == "number" || typeof  params == "string"){
            return routes[name].replace(`{id}`, params);
        }

        else if (typeof params == 'object') {
            let link = routes[name];

            Object.entries(params).forEach(([key, value]) => {
                link = link.replace(`{${key}}`, value);
            });
            return link;
        } else {

            return routes[name];
        }
    }
};
export const setGlobalRoutes = (routes, group = 'dashboard') => {
    window.__AppRoutes = routes[group] ?? {};

};

export const pluginGetRoute = () => {

    setGlobalRoutes(window.__AppRoutes, 'admin');

    return {
        install: (v, options) => {

            v.mixin({
                methods: {
                    getRoute,
                },
            });

            if (parseInt(v.version) > 2) {
                v.provide('getRoute', getRoute);
            }
        },
    };

};





