<template>
    <Head title="Add new user - VIP Fares"></Head>
    <AppMainLayout
        title="Add new user"
        :breadcrumbs="[{title:'Users', link: getRoute('users.index')}, 'Add new user']"
    >
        <template #content>
            <div class="container-sm" >
                <acms-v-tabs
                    :items="{
                    'details':'Details',
                    'permissions': 'Permissions',
                    'team': 'Team',
                }"
                    :active="activeTab"
                    @afterChangeTab="activeTab = $event"
                >
                    <template #tab-details>
                        <acms-v-switcher
                            v-model="form.active"
                            :title="form.active ? 'Active' : 'Not Active'"
                            class="ms-auto"

                        />

                        <div><h3>Personal Data</h3></div>
                        <div class="row">
                            <acms-v-form-field
                                label="First name"
                                name="first_name"
                                placeholder="ex. Doe"
                                modeInput
                                v-model="form.first_name"
                                class="col-4"
                                :errors="form.errors"
                                required

                            />
                            <acms-v-form-field
                                label="Last name"
                                name="last_name"
                                placeholder="ex. John"
                                modeInput
                                v-model="form.last_name"
                                class=" col-4"
                                :errors="form.errors"
                                required

                            />
                            <acms-v-form-field
                                label="Middle name"
                                name="middle_name"
                                placeholder="ex. John"
                                modeInput
                                v-model="form.middle_name"
                                class=" col-4"
                                :errors="form.errors"

                            />
                            <acms-v-form-field
                                label="Personal email address"
                                name="personal_email"
                                modeInput
                                placeholder="ex. john_doe@gmail.com"
                                v-model="form.personal_email"
                                class=" col-4"
                                :errors="form.errors"

                            />
                            <acms-v-form-field
                                label="Personal phone number"
                                name="personal_phone_number"
                                class=" col-4"
                                placeholder="(983) XXX XX XX"
                                :modeInputWithDropdown="{
                                     search:{
                                         keys:['code', 'title'],
                                         excludeSymbols:['+']
                                     },
                                     selectOptions,
                                     phoneCode:true,
                                     keySelect:'phoneCode',
                                     selectedOptionOnInit:{key:'id', value: '232' },
                                }"
                                v-model="form.personal_phone"
                                :errors="form.errors.personal_phone"

                            />
                            <acms-v-form-field
                                label="Date of birth"
                                placeholder="MM/DD/YYYY"
                                modeDatepicker
                                name="date_birth"
                                class=" col-4"
                                v-model="form.date_of_birth"
                                :errors="form.errors.date_of_birth"

                            />
                            <div class="col-12">
                                <acms-v-label  label="Profile picture"/>
                                <dropzone
                                    class="dropzone-block"
                                    name="images"
                                    :maxFiles="1"
                                    :hint="form.errors.image"
                                    v-model="form.image"
                                    @imagesChanger="storeFiles"

                                >
                                </dropzone>
                            </div>
                        </div>

                        <h3>Work Data</h3>
                        <div class="row">
                            <acms-v-form-field
                                label="Display Name"
                                name="display_name"
                                placeholder="ex. John"
                                modeInput
                                v-model="form.display_name"
                                class="col-4"
                                :errors="form.errors"
                                required

                            />
                            <acms-v-form-field
                                label="Display Last Name"
                                name="display_last_name"
                                placeholder="ex. Doe"
                                modeInput
                                v-model="form.display_last_name"
                                class=" col-4"
                                :errors="form.errors"
                                required

                            />
                        </div>
                        <div class="row">
                            <acms-v-form-field
                                label="Work email"
                                name="work_email"
                                placeholder="ex. john_doe@gmail.com"
                                modeInput
                                v-model="form.work_email"
                                class=" col-4"
                                :errors="form.errors"
                                required
                            />
                            <acms-v-form-field
                                label="Work phone number"
                                name="work_phone"
                                class=" col-4"
                                placeholder="(983) XXX XX XX"
                                v-model="form.work_phone"
                                :errors="form.errors.work_phone"
                                required
                                :modeInputWithDropdown="{
                                     selectOptions,
                                      search:{
                                         keys:['code', 'title'],
                                         excludeSymbols:['+']
                                     },
                                     phoneCode:true,
                                     keySelect:'phoneCode',
                                     selectedOptionOnInit:{key:'id', value: '232' },
                                }"

                            />
                            <acms-v-form-field
                                label="Work extension"
                                name="work_extension"
                                modeInput
                                v-model="form.work_extension"
                                :errors="form.errors.work_extension"
                                class="col-4"
                                placeholder="ex. 777"

                            />
                        </div>
                        <gds-credentials
                            :items="form.gds_credentials"
                            @update="form.gds_credentials = $event"
                            :errors="form.errors"
                            :options="gds"

                        />
                    </template>
                    <template #tab-permissions>
                        <UserGroupPermissionsBox
                            label-group="Current group"
                            show-title
                            v-model:group="form.roles"
                            v-model:permissions="form.permissions"

                            :errors="form.errors"
                            :groups-options="roles"
                            :permissions-groups="clients ?? []"
                            @change-tab="locState.currentActivePermissionsTab = $event"
                        />
                    </template>
                    <template #tab-team>
                        <acms-v-form-field
                            label="Current team"
                            name="team_id"
                            :modeSelect="{
                                options: teams,
                                outValueKey:'id'
                            }"
                            v-model="form.team_id"
                            :errors="form.errors?.team_id"
                            class=" col-4"
                            required
                        />
                    </template>
                </acms-v-tabs>
                <acms-v-card title="Permissions Guide" v-if="activeTab == 'permissions'">

                    <permissions-guide
                            :collection="getPermissionsForGuideTable"
                    />
                </acms-v-card>
            </div>
            <acms-v-bottom-actions

                @save="saveHandle"
                @saveAndExit="saveAndExitHandle"
                @cancel="router.get(getRoute('users.index'))"
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserCreatePage',
};
</script>

<script setup="">
import {computed, reactive, ref} from 'vue';
import {Head, router, useForm} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import UserGroupPermissionsBox from "../../boxes/UserGroupPermissionsBox.vue";
import Dropzone from "../../../ui/DropZoneNew.vue";
import {getRoute} from "@plugins/useRoutes.js";
import GdsCredentials from "@pages/users/partials/gdsCredentials.vue";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager.js";
import PermissionsGuide from "@pages/users/partials/permissionsGuide.vue";
import {toKebabCase} from "@libs/utils.js";
import AcmsVFormField from "@ui/FormField/FormField.vue";

const AlertsManager = useAlertsManagerStore()

const props = defineProps({
    roles:Array,
    dialCodes:Array,
    gds:Array,
    clients:Array,
    permissions:Object,
    teams:Array,
    search:String,
    limit:[String,Number],
    actions: {
        type: Array,
        default: () => []
    },
});

const activeTab = ref('details')

const form = useForm({
    image:null,
    active:true,
    first_name: null,
    last_name: null,
    middle_name: null,
    personal_email: null,
    personal_phone: null,
    personal_phone_prefix_id: null,
    date_of_birth: null,
    display_name: null,
    display_last_name: null,
    work_email: null,
    work_phone: null,
    work_phone_prefix_id:null,
    work_extension: null,
    gds_credentials:null,
    contact_view_count:null,
    // role_id: null,
    team_id: null,
    permissions:null,
    roles: {}
})

const selectOptions = props.dialCodes.map(item=>{
    const{id, image, country, code} = item
    return {
        id,code,
        title:country,
        thumbnail: image,
    }
})

const image = ref(null)

const locState = reactive({
    currentActivePermissionsTab: props.clients?.length > 0 ? toKebabCase(props.clients[0].name) : '',
})

const getPermissionsForGuideTable = computed(() => {
    const find = props.clients.find(item => toKebabCase(item.name) == locState.currentActivePermissionsTab)
    return find ? find.permissions : []
})

const storeFiles = (files) => image.value = files;

const submitFormHandle = (callback = ()=>{}) =>{
         form.transform((data)=>{

            return {
                ...data,

                image:image.value ?? [],
                gds_credentials: data.gds_credentials ?? [],
                personal_phone: data.personal_phone?.text ?? null,
                personal_phone_prefix_id: data.personal_phone?.phoneCode.id ?? null,
                work_phone: data.work_phone?.text ?? null,
                work_phone_prefix_id:data.work_phone?.phoneCode.id ?? null,
                permissions: data?.permissions ? Object.values(data.permissions)?.map((item)=>item.map(item=>item.id))?.flat() :  [],
                role: data?.role?.map(item=>item?.id) ?? null,
                team_id: data?.team_id ?? null,
            }
        }).post(getRoute('users.store'),{
            preserveScroll:true,
            onSuccess(){
                if(props.status === 'error'){
                    AlertsManager.add('Server Error', 'error')
                }else{
                    AlertsManager.add('Client successfully created')
                }
                callback?.()
            },
            onError(){

                if(Object.keys(form.errors).length == 1 && form?.errors?.roles || Object.keys(form.errors).length == 2 && form?.errors?.roles){
                    AlertsManager.add('Client has errors, select User Group', 'error')
                }else if(Object.keys(form.errors).length == 1 && form?.errors?.team_id || Object.keys(form.errors).length == 2 && form?.errors?.team_id){
                    AlertsManager.add('Client has errors, select Team', 'error')
                } else{
                    AlertsManager.add('Client has errors', 'error')
                }

            }
        })
}

const saveHandle = () => submitFormHandle()

const saveAndExitHandle = () => {
    submitFormHandle(()=>router.visit(getRoute('users.index')))
}
</script>

<style lang="scss" >

:deep([data-v-select-settings=withCode] .acms-v-select-dropdown){
            width: 400px!important;
        }
</style>
