<template>

    <div class="acms-v-switcher  "
         :class="getClass"
    >

        <template v-if="multiple">

            <div class="d-flex" :class="nativeType == 'checkbox' ? 'flex-column mt-5' : ''">

                <div v-for="(item, i) in options"
                     :key="i"
                     class="acms-v-switcher-multi-item form-check mt-2"
                >
                    <template v-if="nativeType === 'radio'">
                        <input
                            :name="getName"
                            :checked="getCheckedForRadio(item)"
                            :value="item"
                            class="form-check-input"
                            :disabled="item.disabled"
                            :id="setIdForMultiItem(item)"
                            :type="nativeType"
                            @input="changeHandle"
                            :readonly="readonly"
                        >
                        <!--                       -->
                    </template>

                    <template v-if="nativeType === 'checkbox'">

                        <input
                            :name="getName"
                            :value="item?.[optionKeyValue]"
                            v-model="multipleValue"
                            class="form-check-input"
                            :disabled="item.disabled"
                            :id="item?.[optionKeyTitle] ?? setIdForMultiItem( item)"
                            :type="nativeType"
                            @change="changeHandle"
                            :readonly="readonly"
                        >
                    </template>


                    <label class="form-check-label"
                           :for="item?.[optionKeyTitle] ?? setIdForMultiItem(item)"
                    >{{ item?.[optionKeyTitle] ?? item }}</label>
                </div>


            </div>

        </template>


        <template v-else>

            <input class="form-check-input"
                   :type="nativeType"
                   :name="name"
                   :value="modelValue"
                   :checked="modelValue"
                   :id="getName"
                   :disabled="disabled"
                   @input="changeHandle"
            >

            <label class="form-check-label" :for="getName">{{ title }}</label>

        </template>

    </div>


</template>

<script>
export default {
    name: 'AcmsVSwitcher',
};
</script>

<script setup>

import {computed, onBeforeMount, onMounted, ref} from 'vue';
import {defaultAttributesProps} from '@components/ui/FormField/field.props';
import multi from '@components/ui/FormField/elements/Select/toggleTemplates/multi.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    ...defaultAttributesProps,
    modelValue: [Boolean, String, Array, Number],
    title: String,

    typeToggle: Boolean,
    typeCheckbox: Boolean,
    typeRadio: Boolean,

    multiple: Boolean,
    options: [Array],

    optionKeyTitle: {
        type: String,
        default: 'title',
    },
    optionKeyValue: {
        type: String,
        default: 'value',
    },

    round: Boolean,

});


const getCheckedForRadio = (item) => {
    const {modelValue,optionKeyValue} = props
    return item?.[optionKeyValue] ? modelValue == item?.[optionKeyValue] : modelValue == item
}


const initialValueType = typeof props.modelValue

const nativeType = computed(() => props.typeRadio ? 'radio' : 'checkbox');

const getClass = computed(() => {
    const {typeToggle, typeCheckbox, typeRadio, multiple, round} = props;

    const classList = ['form-check'];

    if (multiple) {
        classList.push('acms-v-switcher-multi');
    }

    if (typeToggle || !typeCheckbox && !typeRadio) {
        classList.push('form-switch acms-v-switcher--toggle');
    }

    if (typeCheckbox) {
        classList.push('acms-v-switcher--checkbox');
        if (round) {
            classList.push('round')
        }
    }


    return classList;
});

const computedId = computed(() => {
    return `-id-` + Math.floor(Math.random() * 99999);
});

const setIdForMultiItem = (item) => item + computedId.value;

const getName = computed(() => {
    return props.name ?? 'name' + computedId.value;
});

const multipleValue = ref([])


const changeHandle = (event) => {
    const {modelValue, optionKeyValue} = props;
    let emitValue = modelValue;

    if (event.target.type === 'checkbox') {

        if (props.multiple) {

            emitValue = multipleValue.value

        } else {

            if (initialValueType == 'number') {
                emitValue = event.target.checked ? 1 : 0
            } else {
                emitValue = event.target.checked;
            }

        }

    } else if (event.target.type === 'radio') {
        emitValue = event.target.checked;
    } else {
        emitValue = event.target.value;
    }
    setTimeout(() => {
        emit('update:modelValue', emitValue);
    }, 0);


};

onBeforeMount(() => {
    if (props.multiple) {
        multipleValue.value = props.modelValue ?? []
    }


});

</script>


<style lang="scss">

.acms-v-switcher {
    align-items: center;
    display: flex;
    height: 38px;
    width: max-content;

    &.acms-v-switcher-multi {
        padding-left: 0;
    }

    .form-check-label {
        margin-left: 6px;
    }

    &:not(.form-switch) {
        .form-check-input {
            width: 22px;
            height: 22px;
        }
    }
    .form-check-input {
        margin-top: 0;
    }

    &.form-switch {
        line-height: 24px;
    }
}

.acms-v-switcher-multi-item {
    &:not(:last-child) {
        margin-right: 24px;
    }
}


.acms-v-switcher--checkbox.round input {
    border-radius: 50%;
}

</style>
