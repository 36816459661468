<template>


    <div class="acms-v-bottom-actions ">

        <div class="acms-v-bottom-actions-buttons d-flex justify-content-end gap-3">
            <template v-if="!$slots.default">
                <acms-v-btn title="Cancel"  v-if="!hideCancel" class="btn-border" @click="$emit('cancel')"/>

                <template v-if="!hideSaveButtons">
                    <acms-v-btn title="Save and exit" v-if="!hideSaveAndExit" class="btn-border bg-gray-light" @click="$emit('saveAndExit')"/>
                    <acms-v-btn title="Save " class="btn-primary" @click="$emit('save')"/>
                </template>
            </template>
            <slot></slot>
        </div>
    </div>

</template>

<script>

export default {
    name: 'AcmsVBottomActions',

};
</script>

<script setup="">

import {computed, onMounted, onUnmounted} from "vue";

const props = defineProps({
    hideCancel:Boolean,
    hideSaveAndExit:Boolean,
    positionAbsolute:Boolean,

    hideSaveButtons:Boolean,
});

const getPosition = computed(()=>{
    return props.positionAbsolute ? 'sticky' : 'fixed'
})


onMounted(()=>{
    document.querySelector('main').style.marginBottom = '74px'
})

onUnmounted(()=>{
    document.querySelector('main').style.marginBottom = ''
})


</script>
<style lang="scss">


.acms-v-bottom-actions-buttons {
    height: 74px;
    padding: 16px;
    position: fixed;
    background: #fff;
    bottom: 0;
    right: 0;
    left: 240px;
}



</style>
