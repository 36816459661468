<template>
    <Head title="Departments - VIP Fares"></Head>
    <AppMainLayout
        title="Departments"
    >
        <template v-slot:header-right>
            <acms-v-btn icon="plus" title="Add new department" class="btn-sm btn-primary ms-auto"
                        v-if="actions.includes('Create')"
                        :href="getRoute('departments.create')"/>
        </template>
        <template #content>
            <div class=" ">
                <acms-v-table class="p-4 "
                              :columns="{
                                'id' : '#',
                                'title' : 'Department name',
                                'actions' : 'Actions',
                              }"
                              :columns-width="{
                                    'id': '52px',
                                    'actions': '120px'
                                  }"
                              :data="collection.data"
                              :links="collection.links"
                              :actionsShowFun="getTableRowActions"
                              v-model:page="tableForm.page"
                              sort-keys="id,title"
                              @update:sort="tableSortHandle"
                              @editAction="(item)=>router.get(getRoute('departments.edit', item.id))"
                >

                    <template #header>
                        <AcmsVTableHeader
                            config="search,entries"
                            v-model:search="tableForm.search"
                            v-model:entries="tableForm.limit"
                            search-placeholder="Search"
                        />
                    </template>
                    <template #bodyCell-id="{item}">
                        <div class="text-end">{{ item.id }}</div>
                    </template>
                    <template #bodyCell-title="{item}">
                        <template v-if="actions.includes('Read')">
                            <Link :href="getRoute('departments.edit',  item.id  )" class="text-primary fw-semibold">
                                {{ item.title }}
                            </Link>
                        </template>
                        <div v-else
                             class="text-primary fw-semibold">{{ item.title }}
                        </div>
                    </template>
                </acms-v-table>
            </div>
            <AlertDelete
                v-model:show="locState.alert"
                type="userGroup"
                @delete="deleteRow"
            />
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserIndexPage',
};
</script>

<script setup="">
import {computed, reactive} from 'vue';
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';
import {Head, Link, router} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import useTableSortable from "@ui/Table/useTableSortable.js";
import {getRoute} from "@plugins/useRoutes.js";
import AlertDelete from "@components/app/alerts/AlertDelete.vue";

const props = defineProps({
    collection: Object,
    limit: [String, Number],
    search: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },
});

const locState = reactive({
    alert: null,
})

const getTableRowActions = (item) => {
    const arr = []
    if (props.actions.includes('Read')) {
        arr.push('edit')
    }
    // if (props.actions.includes('Delete')) {
    //     arr.push('delete')
    // }
    return arr
}

const deleteRow = (item) => {
    router.delete(getRoute('departments.destroy', item.id), {
        onFinish() {
            locState.alert = null
        }
    })
}

const {tableForm, tableSortHandle} = useTableSortable(
    getRoute('departments.index'), {
        limit: props.limit ?? 20,
        search: props.search ?? '',
        column: props.column ?? null,
        direction: props.direction ?? null
    })

</script>

<style scoped>

</style>
