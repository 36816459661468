export const MODES_ADDITIONS = [
    'with-icon',
    'with-dropdown',
];

export const MODES = {
    list: [
        'input', 'inputWithDropdown',
        'quantity',
        'textarea', 'textEditor',
        'select',
        'datepicker',
        'timePicker',

        'switcher',
    ],
};

export const ELEMENT_LISTENERS = (mode, $emit) => {
    const objectEvents = {
        inputWithDropdown: {
            'clickOnDropdownOption': ($event) => {
                $emit('clickOnDropdownOption', $event);
            },
        },
        textarea: {

        },
        input: {
            'onInput': ($event) => {
                $emit('onInput', $event);
            },
        },
        quantity: {

        },
        timePicker: {
            'onInput': ($event) => {
                $emit('onInput', $event);
            },
        },
        select: {
            'onSelect': ($event) => {
                $emit('onSelect', $event);
            },

        },
    };
    return objectEvents?.[mode] ?? {};
};




export const SELECT_VIEW_TEMPLATES  = ['withCode', 'cityAirport', 'dropdown', 'multiCheckbox']


const SELECT_SLOTS = {
    'toggle' : 'select_toggle'
}
const INPUT_SLOTS = []

