<template>
    <Head title="Permissions - VIP Fares"></Head>
    <AppMainLayout
        title="Permissions"
    >
        <template #content>
            <div class="">
                <acms-v-table class="p-4 "
                              :columns="{
                                'id' : '#',
                                'name' : 'Name',
                                'project' : 'Project/Sistem',
                                'function' : 'Function',
                                'description' : 'Description',
                                'actions' : 'Actions',
                                   }"
                              :columns-width="{
                                    'id': '52px',
                                    'name' : '240px',
                                    'project' : '190px',
                                    'function' : '160px',
                                    'description' : '160px',
                                    'actions': '120px'
                                  }"
                              :data="collection.data"
                              v-model:page="tableForm.page"
                              :links="collection.links"
                              :actions="getTableActions"
                              keyForPublished="active"
                              sort-keys="id,name,status,project,function,description"
                              @update:sort="tableSortHandle"
                              @editAction="(item)=>router.get(getRoute('user.permissions.edit', item.id))"
                              @publishAction="(item)=>router.post(getRoute('user.permissions.active', item.id), {id:item.id})"
                >
                    <template #header>
                        <AcmsVTableHeader
                            config="search,entries"
                            v-model:search="tableForm.search"
                            v-model:entries="tableForm.limit"
                            search-placeholder="Search"
                        />
                    </template>
                    <template #bodyCell-id="{item}">
                        <div class="text-end">{{ item.id }}</div>
                    </template>
                    <template #bodyCell-name="{item}">
                        <template v-if="actions.includes('Read')">
                            <Link :href="getRoute('user.permissions.edit',  item.id  )" class="text-primary fw-semibold ">{{ item.name  }}</Link>
                        </template>
                        <div v-else
                             class="text-primary fw-semibold">{{ item.name }}
                        </div>
                    </template>
                </acms-v-table>
            </div>
        </template>
    </AppMainLayout>
</template>

<script>
export default {
    name: 'UserIndexPage',
};
</script>

<script setup="">
import AcmsVTableHeader from '@components/ui/Table/partials/TableHeader.vue';
import {Head, Link, router} from '@inertiajs/vue3'
import AppMainLayout from "@layouts/AppMainLayout.vue";
import useTableSortable from "@ui/Table/useTableSortable.js";
import {getRoute} from "@plugins/useRoutes.js";
import {computed} from "vue";
const props = defineProps({
    collection: Object,
    limit: [String,Number],
    search: String,
    column: String,
    direction: String,
    actions: {
        type: Array,
        default: () => []
    },
});

const {tableForm, tableSortHandle} = useTableSortable(
    getRoute('user.permissions.index'), {
        limit: props.limit ??  20,
        search: props.search ?? '',
        column: props.column ?? null,
        direction:props.direction ?? null
    })


const getTableActions = computed(() => {
    const arr = []
    if (props.actions.includes('Read')) {
        arr.push('edit')
    }
    if (props.actions.includes('Update')) {
        arr.push('publish')
    }
    return arr
})

</script>

<style scoped>

</style>
