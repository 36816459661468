const clickOutside = {
    mounted (el, binding, vnode) {
        let vm = vnode.context;
        let callback = binding.value;


        el.clickOutsideEvent = function (event) {
            // console.log('click outside 1 ',  event.target);
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }

        };
        setTimeout( () => {

            document.body.addEventListener('click', el.clickOutsideEvent);
        }, 0);

    },
    beforeUnmount (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};
export default clickOutside;
