<template>

    <div class="acms-v-table-column-title" :class="getClass">

        <div class="acms-v-table-column-title-text" @click="clickHandle">
            <slot></slot>
        </div>

        <div v-if="sort?.direction && sort.column == columnKey" class="acms-v-table-column-sort"></div>

    </div>

</template>

<script>
export default {
    name: 'AcmsVTableColumnTitle',
};
</script>


<script setup="">
import { computed, ref } from 'vue';

const emit = defineEmits(['update:sort', '']);
const props = defineProps({
    sort: Object,
    isSortable: Boolean,
    columnKey:String,
});


const getClass = computed(() => {
    let objectClasses = {}

    const {direction, column} = props.sort

        if (direction && column == props.columnKey) {

            Object.assign(objectClasses, {
                'sorted asc': direction?.toLowerCase() == 'asc'  ,
                'sorted desc': direction?.toLowerCase() == 'desc' ,
            })
        }
    Object.assign(objectClasses, { 'sortable' : props.isSortable})
    return objectClasses
});

const clickHandle = () => {

    if (props.isSortable) {

        let nextDirection
        const {direction, column} = props.sort

        if (column != props.columnKey) {
            nextDirection = 'asc';
        }else{
            if (direction == '') {
                nextDirection = 'asc';
            } else if (direction.toLowerCase() == 'asc') {
                nextDirection = 'desc';
            } else {
                nextDirection = '';
            }
        }

        emit('update:sort', nextDirection);
    }
};

</script>

<style lang="scss">

$ico-sort-top :  url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.65889 5.59366C8.29446 5.24657 7.70262 5.24657 7.33819 5.59366L3.60641 9.14783C3.33819 9.40328 3.25947 9.78369 3.40525 10.1169C3.55102 10.4501 3.88921 10.6667 4.26822 10.6667H11.7318C12.1079 10.6667 12.449 10.4501 12.5947 10.1169C12.7405 9.78369 12.6589 9.40328 12.3936 9.14783L8.6618 5.59366H8.65889Z' fill='%231F2937'/%3E%3C/svg%3E%0A");
.acms-v-table-column-title {
    display: flex;
    align-items: center;
    position: relative;
    &.sorted {
        padding-right: 16px;
        .acms-v-table-column-title-text {
            font-weight: 600;
            color: #000;
        }
    }

    &.sortable{
        cursor: pointer;

        &:hover:not(.sorted) {
            color: #000;
        }
    }

    &.sorted.sortable{

        .acms-v-table-column-sort{
            display: block;
        }
    }

    .acms-v-table-column-sort{
        display: none;
    }

    &.desc{
        .acms-v-table-column-sort{
            transform: rotate(180deg);
        }
    }

    .acms-v-table-column-sort{
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: -16px;
        margin-left: 4px;
        background-image: $ico-sort-top;
    }
}


</style>
